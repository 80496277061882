@import '../../css/vars';

$CNAME: 'c-block-footer-compact';

.#{$CNAME} {
	position: relative;
	background-size: 120rem auto;
	background-position: bottom center;
	background-repeat: repeat-x;
	overflow-x: hidden;
	padding-top: 6rem;
}

.#{$CNAME}--main {}

.#{$CNAME}--menu-item p + p {
	margin-top: 0;
}

.#{$CNAME}--icons {
	display: flex;
}

.#{$CNAME}--icons > * + * {
	margin-left: .75rem;
}

.#{$CNAME}--small-print {
	margin-top: 15rem;
}

.#{$CNAME}--small-print .h-type {
	margin: 0 auto 0 0;
	text-align: left;
}

.#{$CNAME}--wrapper.min-48rem .#{$CNAME}--small-print .h-type {
	margin: 0 0 0 auto;
	text-align: right;
}

.#{$CNAME}--small-print p {
	font-size: .75rem;
	color: color('oxeye');
}

.#{$CNAME}--small-print a {
	color: color('ice');
	text-decoration: underline;
}

.#{$CNAME}--small-print a:hover {
	color: color('ice');
}

.#{$CNAME} .c-base-background-image {
	// Set background image behind floating fish
	z-index: -1;
	// Blend with background
	opacity: .5;
}
