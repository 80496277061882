@import '../../css/vars';

$CNAME: 'c-base-animated-border';

$borderWidth: .1875rem;
$borderColor: color('ice');

.#{$CNAME} {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.#{$CNAME}.m-animation-complete {
	border: $borderWidth solid $borderColor;
}

.#{$CNAME}--svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.#{$CNAME}.m-animation-complete .#{$CNAME}--svg {
	display: none;
}

.#{$CNAME}--border {
	fill: none;
	stroke: $borderColor;
	stroke-width: $borderWidth * 2;
}
