// A list of all available font styles and the font properties associated with them.
$fonts: (

// 🚧 DO NOT EDIT - CS ONLY BEYOND THIS POINT 🚧
	sans-500-n: (
		font-family: 'Helvetica,Arial,sans-serif',
		font-weight: 500,
		font-style: normal,
		color: #FF00FF,
	),
	sans-500-i: (
		font-family: 'Helvetica,Arial,sans-serif',
		font-weight: 500,
		font-style: italic,
		color: #FF00FF,
	),
	sans-700-n: (
		font-family: 'Helvetica,Arial,sans-serif',
		font-weight: 700,
		font-style: normal,
		color: #FF00FF,
	),
	sans-700-i: (
		font-family: 'Helvetica,Arial,sans-serif',
		font-weight: 700,
		font-style: italic,
		color: #FF00FF,
	),

	// Everything above this will be removed
	muli-400-n: (
		font-family: "'Muli',Helvetica,Arial,sans-serif",
		font-style: normal,
		font-weight: 400,
	),
	muli-400-i: (
		font-family: "'Muli',Helvetica,Arial,sans-serif",
		font-style: italic,
		font-weight: 400,
	),
	muli-600-n: (
		font-family: "'Muli',Helvetica,Arial,sans-serif",
		font-style: normal,
		font-weight: 600,
	),
	muli-600-i: (
		font-family: "'Muli',Helvetica,Arial,sans-serif",
		font-style: italic,
		font-weight: 600,
	),
	muli-700-n: (
		font-family: "'Muli',Helvetica,Arial,sans-serif",
		font-style: normal,
		font-weight: 700,
	),
	muli-700-i: (
		font-family: "'Muli',Helvetica,Arial,sans-serif",
		font-style: italic,
		font-weight: 700,
	),
	// Only used for Bold
	muli-800-n: (
		font-family: "'Muli',Helvetica,Arial,sans-serif",
		font-style: normal,
		font-weight: 800,
	),
	muli-800-i: (
		font-family: "'Muli',Helvetica,Arial,sans-serif",
		font-style: italic,
		font-weight: 800,
	),
	debug: (
		font-family: 'serif',
		font-weight: 500,
		font-style: normal,
	),
);

// Function to retrieve a font value based on its key.
@function font($name) {
	@if map-has-key($fonts, $name) {
		@return map-get($fonts, $name);
	}
	@error 'Invalid font: `#{$name}`';
}

// Applies font styling based on a key.
@mixin font-style($name) {
	@each $prop-name, $prop-value in font($name) {
		#{$prop-name}: #{$prop-value};
	}
}
