// A list of all reused styles that may not fit in the other categories.
$base-styles: (
	// Form styles
	form-field: (
		display: block,
		width: 100%,
		padding: .75rem 1rem,
		background-color: color('light'),
		border: .0625rem solid color('neutral-light'),
	),
	form-field-text: (
		font-size: 1rem,
		line-height: 1.5rem,
		color: color('dark'),
	),

	// Image styles
	image-shadow: (
		box-shadow: .1875rem .1875rem 2.1875rem 0 rgba(0, 0, 0, .6),
	),
	image-border: (
		border: .375rem solid color('ice'),
	),

	// Transition durations
	transition-duration-extra-fast: 100ms,
	transition-duration-fast: 250ms,
	transition-duration-medium: 500ms,
	transition-duration-slow: 750ms,
	transition-duration-extra-slow: 1000ms,
);

// Function to retrieve a text value based on its key.
@function base-style-value($name) {
	@if map-has-key($base-styles, $name) {
		@return map-get($base-styles, $name);
	}
	@error 'Invalid base style: `#{$name}`';
}

@mixin base-style($name) {
	@each $prop-name, $prop-value in base-style-value($name) {
		#{$prop-name}: #{$prop-value};
	}
}
