@import '../../css/vars';

$CNAME: 'c-block-testimonials-carousel';

.#{$CNAME} {
	padding-top: 65px;

	@media (min-width: 992px) {
		padding-top: 0;

	}

	.#{$CNAME}--heading {
		.c-base-text{
			max-width: none;
			text-align: center;

			h2 {
				font-family: Muli;
				font-size: 32px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.31;
				letter-spacing: normal;
				text-align: center;
				padding-bottom: 19px;
				display: block;
			}
		}
	}
}

.#{$CNAME} {
	.testimonials-slider-wrapper {
		max-width: 1285px;
		margin: auto;
		width: 101%;

		@media (max-width: 991px){
			width: 99%;
		}

		@media (max-width: 767px){
			width: 101%;
		}


		.testimonials-slider-items {
			margin: 0 -24px;

			@media (max-width: 767px){
				margin: 0;
			}

			.testimonials-slider-item {
				padding: 0 24px;

				.content-wrapper {
					padding: 35.8px 29px 69.9px 37px;
					box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.16);
					background-color: rgba(0, 63, 93, 0.78);
					position: relative;
					font-family: Muli;
					font-size: 16px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.5;
					letter-spacing: normal;
					text-align: left;
					color: #fff;

					p{
						display: inline;
					}

					.company-logo-wrapper {
						width: 88px;
						height: 88px;
						background-color: #5b6670;
						border-radius: 50%;
						position: absolute;
						bottom: -44px;
						left: 0;
						right: 0;
						margin: auto;
					}
				}

				.title-and-company {
					padding-top: 65px;

					h3.title,
					h4.company {
						font-family: Muli;
						font-size: 16px;
						font-weight: 600;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.5;
						letter-spacing: 1.28px;
						text-align: center;
						color: #96d5ea;
						text-transform: uppercase;
					}

					h4.company {
						font-style: italic;
						letter-spacing: 0.48px;
						text-transform: none;
					}
				}
				&.no-logo{
					.title-and-company {
						padding-top: 30px;
					}
				}
			}
		}
	}
}

.#{$CNAME} {
	.testimonials-slider-wrapper {
		.testimonial-slidernav {
			display: flex;
			margin-top: 65px;
			justify-content: center;
			align-items: center;

			span.prev {
				width: 13px;
				height: 13px;
				display: block;
				position: relative;
				transform: rotate(45deg);
				cursor: pointer;
				-webkit-tap-highlight-color: rgba(0,0,0,0);

				&:before {
					content: "";
					position: absolute;
					width: 100%;
					height: 1px;
					background-color: #fff;
					bottom: 0;
					border-radius: 50px;
					transition: background-color 0.3s ease-in-out;
				}

				&:after {
					content: "";
					position: absolute;
					width: 1px;
					height: 100%;
					background-color: #fff;
					left: 0;
					border-radius: 50px;
					transition: background-color 0.3s ease-in-out;
				}
			}

			span.next {
				width: 13px;
				height: 13px;
				display: block;
				position: relative;
				transform: rotate(45deg);
				cursor: pointer;
				-webkit-tap-highlight-color: rgba(0,0,0,0);

				&:before {
					content: "";
					position: absolute;
					width: 100%;
					height: 1px;
					background-color: #fff;
					top: 0;
					border-radius: 50px;
					transition: background-color 0.3s ease-in-out;
				}

				&:after {
					content: "";
					position: absolute;
					width: 1px;
					height: 100%;
					background-color: #fff;
					right: 0;
					border-radius: 50px;
					transition: background-color 0.3s ease-in-out;
				}
			}

			span.next,
			span.prev{
				&:hover{
					&:before,
					&:after{
						background-color: #96d5ea;
					}
				}
			}


			.slick-dots-wrapper{
				padding: 0 17px;
				.slick-dots {
					display: flex;
					justify-content: center;

					@media (max-width: 767px) {
						display: none!important;
					}

					li {
						padding: 0 4px;
						display: none;
						
						@media (min-width: 990px) {
							&:nth-child(3n+1) {
								display: block;
							}

						}
						@media (max-width: 990px) and (min-width: 768px) {
							&:nth-child(2n+1) {
								display: block;
							}

						}

						@media (max-width: 767px){
							display: block;
						}

						button {
							width: 10px;
							height: 10px;
							border-radius: 50%;
							background-color: #dbdbdb;
							cursor: pointer;
						}


						&:hover{
							button{
								background-color: #96d5ea;
							}
						}

						&:focus,
						&:active{

							@media (max-width: 992px) {
								button{
									background-color: #dbdbdb;
								}
							}
						}

						&.slick-active{
							button{
								//background-color: #dbdbdb!important;
							}
						}


						&:hover{
							@media (max-width: 992px) {
								button{
									background-color: #dbdbdb;
								}

							}
						}
					}
				}
			}
		}
	}
}


/* Slider */
.slick-slider
{
	position: relative;

	display: block;
	box-sizing: border-box;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list
{
	position: relative;

	display: block;
	overflow: hidden;

	margin: 0;
	padding: 0;
}
.slick-list:focus
{
	outline: none;
}
.slick-list.dragging
{
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track
{
	position: relative;
	top: 0;
	left: 0;

	display: block;
	margin-left: auto;
	margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
	display: table;

	content: '';
}
.slick-track:after
{
	clear: both;
}
.slick-loading .slick-track
{
	visibility: hidden;
}

.slick-slide
{
	display: none;
	float: left;

	height: 100%;
	min-height: 1px;
}
[dir='rtl'] .slick-slide
{
	float: right;
}
.slick-slide img
{
	display: block;
}
.slick-slide.slick-loading img
{
	display: none;
}
.slick-slide.dragging img
{
	pointer-events: none;
}
.slick-initialized .slick-slide
{
	display: block;
}
.slick-loading .slick-slide
{
	visibility: hidden;
}
.slick-vertical .slick-slide
{
	display: block;

	height: auto;

	border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
	display: none;
}

/*
///////////////////////
slider controls button
//////////////////////
*/

.slider-controls{
	display: flex;
	justify-content: center;
	.slide-m-next{
		&::after{
			content: '';
			//background-image: url('#{$images-path}/arrow-right.svg');
			height: 10px;
			width: 52px;
			display: block;
		}
	}

	.slide-m-prev{
		&::after{
			content: '';
			//background-image: url('#{$images-path}/arrow-left.svg');
			height: 10px;
			width: 52px;
			display: block;
		}
	}

	button{
		background: transparent;
		border: none;
		cursor: pointer;
		margin: 0 5px;

		&:hover{
			opacity: .7;
		}
	}

	.slick-disabled {
		opacity: .7;
	}
}

.c-page-homepage--content {
	.c-block-testimonials-carousel {
		.testimonials-slider-wrapper{
			width: 87% !important;

			.testimonials-slider-items {
				margin: 0 -24px!important;
			}
		}
	}
}

.c-block-testimonials-carousel {
	.testimonials-slider-wrapper {
		.testimonials-slider-items:not(.slick-dotted){
			+.testimonial-slidernav {
				display: none!important;
			}
		}
	}
}