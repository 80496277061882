/* Fonts
============================================================= */

/*

Font Sources
-----------------------------

See _font_faces.scss

Font Usage
-----------------------------

See _vars_fonts.scss or /component-library
@incldue font-style('font-name')

Supported Weights
-----------------------------

See _vars_fonts.scss or /component-library

*/

/* Base
============================================================= */

.h-type {
	@include font-style('debug'); // Debug font, this shouldn't ever appear and will visually look different than other fonts.
	color: #ff0000; /* If you see this color, you haven't specified a color scheme */
	display: block;
	width: 100%;
	max-width: 64rem; /* This is the cap on line length */
	text-align: left; /* Default text alignment */
}

/* Modifiers
============================================================= */

/* Alignment
----------------------------- */

.h-type.h-type--block-left {}
.h-type.h-type--block-center {margin: 0 auto;}
.h-type.h-type--block-right {margin-left: auto;}

.h-type.h-type--text-left {text-align: left;}
.h-type.h-type--text-center {text-align: center;}
.h-type.h-type--text-right {text-align: right;}
.h-type.h-type--text-justify {text-align: justify;}

/* Misc
 * Note: Other modifiers can be defined at element/class
 * level below. These modifiers should be prefixed
 * with "h-type--" to indicate they are a modifier
 *
 * An example would be "h-type--featured"
----------------------------- */

/* Themes
 * These are special modifiers that may change the appearance
 * of multiple elements at once. This is a way
 * to group multiple modifiers together instead of creating
 * unique instances for each one.
 *
 * Themes may be stacked on each other but conflicts
 * need to be resolved on a case by case bases.
 *
 * A theme is not required for H-Type to function but is
 * highly recommended for coloring.
============================================================= */

.h-type.h-type--theme-light-type {
}
.h-type.h-type--theme-dark-type {
}

/* Columns
----------------------------- */

.h-type.h-type--1-col {}

.h-type.h-type--2-col {
    max-width: 99rem;
    -webkit-columns: 2 24rem;
            columns: 2 24rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
}

.h-type.h-type--3-col {
    max-width: 150rem;
    -webkit-columns: 3 16rem;
            columns: 3 16rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
}

/* H-Type Override
 * This is a class that is reserved for modification to h-type
 * from various sources. Do not place any css in this class from within H-Type.
----------------------------- */

.h-type.h-type--override {
	/* Reserved, do not place anything in here */
}

/* Headings
============================================================= */

.h-type h1,
.h-type .h1{
	@include font-style('muli-600-n');
	font-size: 2rem;
	line-height: 2.5rem;
	letter-spacing: 0;
	text-transform: none;
	color: currentColor;
	position: relative;
	display: block;
}

@media screen and (min-width: 32em) {
	.h-type h1,
	.h-type .h1 {
		font-size: 2.375rem;
		line-height: 3rem;
	}
}

.h-type h1:before,
.h-type .h1:before{
}

.h-type.h-type--theme-dark-type h1,
.h-type.h-type--theme-dark-type .h1{
	color: color('ink');
}

.h-type.h-type--theme-light-type h1,
.h-type.h-type--theme-light-type .h1{
	color: color('ice');
}

/* --- */

.h-type h2,
.h-type .h2{
	@include font-style('muli-600-n');
	font-size: 1.75rem;
	line-height: 2.4rem;
	letter-spacing: 0;
	text-transform: none;
	color: currentColor;
	position: relative;
	display: block;
}

@media screen and (min-width: 32em) {
	.h-type h2,
	.h-type .h2 {
	}
}

.h-type h2::before,
.h-type .h2::before{
}

@media screen and (min-width: 32em) {
	.h-type h2::before,
	.h-type .h2::before {
	}
}

.h-type.h-type--theme-dark-type h2,
.h-type.h-type--theme-dark-type .h2{
	color: color('ink');
}

.h-type.h-type--theme-light-type h2,
.h-type.h-type--theme-light-type .h2{
	color: color('oxeye');
}

/* --- */

.h-type h3,
.h-type .h3{
	@include font-style('muli-600-n');
	font-size: 1.25rem;
	line-height: 1.75rem;
	letter-spacing: .1rem;
	text-transform: uppercase;
	color: currentColor;
	position: relative;
	display: block;
}

@media screen and (min-width: 32em) {
	.h-type h3,
	.h-type .h3{
	}
}

.h-type h3:before,
.h-type .h3:before{
}

.h-type.h-type--theme-dark-type h3,
.h-type.h-type--theme-dark-type .h3{
	color: color('ink');
}

.h-type.h-type--theme-light-type h3,
.h-type.h-type--theme-light-type .h3{
	color: color('ice');
}

/* --- */

.h-type h4,
.h-type .h4{
	@include font-style('muli-600-n');
	font-size: 1rem;
	line-height: 1.75rem;
	letter-spacing: 0.0625rem;
	text-transform: uppercase;
	color: currentColor;
	position: relative;
	display: block;
}

.h-type h4:before,
.h-type .h4:before{
}

.h-type.h-type--theme-dark-type h4,
.h-type.h-type--theme-dark-type .h4{
	color: color('ink');
}

.h-type.h-type--theme-light-type h4,
.h-type.h-type--theme-light-type .h4{
	color: color('oxeye');
}

/* H5 are not supported. */

.h-type h5,
.h-type .h5{
}

.h-type.h-type--theme-dark-type h5,
.h-type.h-type--theme-dark-type .h5{
}

.h-type.h-type--theme-light-type h5,
.h-type.h-type--theme-light-type .h5{
}

/* H6 are not supported. */

.h-type h6,
.h-type .h6{
}

.h-type.h-type--theme-dark-type h6,
.h-type.h-type--theme-dark-type .h6{
}

.h-type.h-type--theme-light-type h6,
.h-type.h-type--theme-light-type .h6{
}

/* Paragraphs
============================================================= */

.h-type p,
.h-type .p {
	@include font-style('muli-400-n');
	font-size: 1.0625rem;
	line-height: 2rem;
	letter-spacing: 0;
	text-transform: none;
	color: currentColor;
	position: relative;
	display: block;
}

.h-type.h-type--theme-dark-type p {
	color: color('ink');
}
.h-type.h-type--theme-light-type p {
	color: color('oxeye');
}

/* Bold / Italic
============================================================= */

.h-type b,
.h-type strong {
	font-weight: 800;
}

.h-type i,
.h-type em {
	font-style: italic;
}

/* Underline
============================================================= */

.h-type u {
		text-decoration: underline;
}

/* Strikethrough
============================================================= */

.h-type s {
	text-decoration: line-through;
}

/* Horizontal Rules
============================================================= */

.h-type hr {
	width: 100%;
	height: 0.875rem;
	background-image: url(/assets/ui/wavy-line.png);
	background-size: auto 0.875rem;
	background-repeat: repeat-x;
	background-position: center center;
	padding: 2rem;
}

.h-type.h-type--theme-dark-type hr {

}

.h-type.h-type--theme-light-type hr {

}

/* Lists
============================================================= */

.h-type ol,
.h-type ul {
	@include font-style('muli-400-n');
	font-size: 1rem;
	line-height: 2.25rem;
	letter-spacing: 0;
	text-transform: none;
	color: currentColor;
	position: relative;
	display: block;
}

.h-type ol {
	list-style: none; // decimal
	counter-reset: item; // Used in cases where you have custom number before elements
	//list-style-type: space-counter; // Used in cases where you have custom number before elements
	margin-left: 1.75rem;
}

.h-type ul {
	list-style-type: none; // disc
	margin-left: .75rem;
}

.h-type li {
	display: list-item;
	position: relative;
}

.h-type.align-center li {
	display: list-item;
}

.h-type li > ol {
	margin-top: 0rem;
	margin-left: 1.725rem;
}
.h-type li > ul {
	margin-top: 0rem;
	margin-left: .125rem
}
.h-type li + li {
	margin-top: .5rem;
}

.h-type li::before {
}

.h-type.align-center li::before {
	display: inline;
}

.h-type ol > li {
	counter-increment: item;
}

.h-type ol > li::before {
	content: counters(item, ".") " ";
	padding-right: .3125rem;
	@include font-style('muli-600-n');
	position: absolute;
	display: inline-block;
	text-align: right;
	right: 100%;
	top: 0;
}

.h-type ul > li {
	padding-left: 0.875rem;
}

.h-type ul > li::before {
	content: "\200B"; // 0 width space for accessability, see https://developer.mozilla.org/en-US/docs/Web/CSS/list-style#Accessibility_concerns
	width: .5rem;
	height: .125rem;
	display: inline-block;
	vertical-align: middle;
	position: absolute;
	top: 1.125rem;
	left: 0;
}

.h-type.h-type--theme-dark-type ol {
		color: color('ink');
}
.h-type.h-type--theme-dark-type ul {
		color: color('ink');
}
.h-type.h-type--theme-dark-type ol > li::before {
		color: color('ink');
}
.h-type.h-type--theme-dark-type li ol > li::before {
		color: color('ink');
}
.h-type.h-type--theme-dark-type ul > li::before {
		background-color: color('ink');
}
.h-type.h-type--theme-dark-type li ul > li::before {
		background-color: color('ink');
}
.h-type.h-type--theme-light-type ol {
		color: color('oxeye');
}
.h-type.h-type--theme-light-type ul {
		color: color('oxeye');
}
.h-type.h-type--theme-light-type ol > li::before {
		color: color('ice');
}
.h-type.h-type--theme-light-type ul > li::before {
		background-color: color('ice');
}
.h-type.h-type--theme-light-type li ol > li::before {
		color: color('ice');
}
.h-type.h-type--theme-light-type li ul > li::before {
		background-color: color('ice');
}

// Center Overrides
.h-type.h-type--text-center ol > li,
.h-type.h-type--text-center ul > li {
	padding: 0;
}

.h-type.h-type--text-center ol > li::before,
.h-type.h-type--text-center ul > li:before {
	display: none;
}

/* Blockquote
============================================================= */

.h-type blockquote {
	@include font-style('muli-600-n');
	font-size: 2rem;
	line-height: 2.6rem;
	letter-spacing: normal;
	text-transform: none;
	position: relative;
	display: block;
	padding: 1rem 0;
}

@media screen and (min-width: 48em) {
	.h-type blockquote {
		padding-left: 3rem;
	}
}

@media screen and (min-width: 48em) {
	.h-type blockquote::before {
		content: "“";
		position: absolute;
		left: 2rem;
	}

	.h-type blockquote::after {
		content: "”";
	}
}

.h-type.h-type--theme-dark-type blockquote {
	color: color('neutral-dark');
}
.h-type.h-type--theme-light-type blockquote {
	color: color('oxeye');
}

/* Unique Classes
============================================================= */

.h-type p.large{
	@include font-style('muli-400-n');
	font-size: 1.35rem;
	line-height: 2.35rem;
	letter-spacing: 0;
	text-transform: none;
	color: currentColor;
	position: relative;
	display: block;
}

.h-type.h-type--theme-dark-type p.large {
	color: color('ink');
}

.h-type.h-type--theme-light-type p.large {
	color: color('oxeye');
}

.h-type p.small{
	@include font-style('muli-400-n');
	font-size: .8rem;
	line-height: 1.45rem;
	letter-spacing: 0;
	text-transform: none;
	color: currentColor;
	position: relative;
	display: block;
}

.h-type.h-type--theme-dark-type p.small {
	color: color('ink');
}

.h-type.h-type--theme-light-type p.small {
	color: color('oxeye');
}

/* Anchors
============================================================= */

.h-type a {
	text-decoration-style: solid;
	text-decoration-line: underline;
	text-decoration-color: currentColor;
	color: color('ice');
}

.h-type a:hover {
	color: color('ice');
	text-decoration: none !important;
}

h-type a:visited {
	color: color('marine');
}

.h-type h1 a,
.h-type .h1 a,
.h-type a h1,
.h-type a.h1 {}

.h-type h1 a:hover,
.h-type .h1 a:hover,
.h-type a:hover h1,
.h-type a:hover.h1 {}

.h-type.h-type--theme-dark-type h1 a,
.h-type.h-type--theme-dark-type .h1 a,
.h-type.h-type--theme-dark-type a h1,
.h-type.h-type--theme-dark-type a.h1 {}

.h-type.h-type--theme-dark-type h1 a:hover,
.h-type.h-type--theme-dark-type .h1 a:hover,
.h-type.h-type--theme-dark-type a:hover h1,
.h-type.h-type--theme-dark-type a:hover.h1 {}

.h-type.h-type--theme-light-type h1 a,
.h-type.h-type--theme-light-type .h1 a,
.h-type.h-type--theme-light-type a h1,
.h-type.h-type--theme-light-type a.h1 {}

.h-type.h-type--theme-light-type h1 a:hover,
.h-type.h-type--theme-light-type .h1 a:hover,
.h-type.h-type--theme-light-type a:hover h1,
.h-type.h-type--theme-light-type a:hover.h1 {}

.h-type h2 a,
.h-type .h2 a,
.h-type a h2,
.h-type a.h2 {}

.h-type h2 a:hover,
.h-type .h2 a:hover,
.h-type a:hover h2,
.h-type a:hover.h2 {}

.h-type.h-type--theme-dark-type h2 a,
.h-type.h-type--theme-dark-type .h2 a,
.h-type.h-type--theme-dark-type a h2,
.h-type.h-type--theme-dark-type a.h2 {}

.h-type.h-type--theme-dark-type h2 a:hover,
.h-type.h-type--theme-dark-type .h2 a:hover,
.h-type.h-type--theme-dark-type a:hover h2,
.h-type.h-type--theme-dark-type a:hover.h2 {}

.h-type.h-type--theme-light-type h2 a,
.h-type.h-type--theme-light-type .h2 a,
.h-type.h-type--theme-light-type a h2,
.h-type.h-type--theme-light-type a.h2 {}

.h-type.h-type--theme-light-type h2 a:hover,
.h-type.h-type--theme-light-type .h2 a:hover,
.h-type.h-type--theme-light-type a:hover h2,
.h-type.h-type--theme-light-type a:hover.h2 {}

.h-type h3 a,
.h-type .h3 a,
.h-type a h3,
.h-type a.h3 {}

.h-type h3 a:hover,
.h-type .h3 a:hover,
.h-type a:hover h3,
.h-type a:hover.h3 {}

.h-type.h-type--theme-dark-type h3 a,
.h-type.h-type--theme-dark-type .h3 a,
.h-type.h-type--theme-dark-type a h3,
.h-type.h-type--theme-dark-type a.h3 {}

.h-type.h-type--theme-dark-type h3 a:hover,
.h-type.h-type--theme-dark-type .h3 a:hover,
.h-type.h-type--theme-dark-type a:hover h3,
.h-type.h-type--theme-dark-type a:hover.h3 {}

.h-type.h-type--theme-light-type h3 a,
.h-type.h-type--theme-light-type .h3 a,
.h-type.h-type--theme-light-type a h3,
.h-type.h-type--theme-light-type a.h3 {}

.h-type.h-type--theme-light-type h3 a:hover,
.h-type.h-type--theme-light-type .h3 a:hover,
.h-type.h-type--theme-light-type a:hover h3,
.h-type.h-type--theme-light-type a:hover.h3 {}

.h-type h4 a,
.h-type .h4 a,
.h-type a h4,
.h-type a.h4 {}

.h-type h4 a:hover,
.h-type .h4 a:hover,
.h-type a:hover h4,
.h-type a:hover.h4 {}

.h-type.h-type--theme-dark-type h4 a,
.h-type.h-type--theme-dark-type .h4 a,
.h-type.h-type--theme-dark-type a h4,
.h-type.h-type--theme-dark-type a.h4 {}

.h-type.h-type--theme-dark-type h4 a:hover,
.h-type.h-type--theme-dark-type .h4 a:hover,
.h-type.h-type--theme-dark-type a:hover h4,
.h-type.h-type--theme-dark-type a:hover.h4 {}

.h-type.h-type--theme-light-type h4 a,
.h-type.h-type--theme-light-type .h4 a,
.h-type.h-type--theme-light-type a h4,
.h-type.h-type--theme-light-type a.h4 {}

.h-type.h-type--theme-light-type h4 a:hover,
.h-type.h-type--theme-light-type .h4 a:hover,
.h-type.h-type--theme-light-type a:hover h4,
.h-type.h-type--theme-light-type a:hover.h4 {}

.h-type h5 a,
.h-type .h5 a,
.h-type a h5,
.h-type a.h5 {}

.h-type h5 a:hover,
.h-type .h5 a:hover,
.h-type a:hover h5,
.h-type a:hover.h5 {}

.h-type.h-type--theme-dark-type h5 a,
.h-type.h-type--theme-dark-type .h5 a,
.h-type.h-type--theme-dark-type a h5,
.h-type.h-type--theme-dark-type a.h5 {}

.h-type.h-type--theme-dark-type h5 a:hover,
.h-type.h-type--theme-dark-type .h5 a:hover,
.h-type.h-type--theme-dark-type a:hover h5,
.h-type.h-type--theme-dark-type a:hover.h5 {}

.h-type.h-type--theme-light-type h5 a,
.h-type.h-type--theme-light-type .h5 a,
.h-type.h-type--theme-light-type a h5,
.h-type.h-type--theme-light-type a.h5 {}

.h-type.h-type--theme-light-type h5 a:hover,
.h-type.h-type--theme-light-type .h5 a:hover,
.h-type.h-type--theme-light-type a:hover h5,
.h-type.h-type--theme-light-type a:hover.h5 {}

.h-type h6 a,
.h-type .h6 a,
.h-type a h6,
.h-type a.h6 {}

.h-type h6 a:hover,
.h-type .h6 a:hover,
.h-type a:hover h6,
.h-type a:hover.h6 {}

.h-type.h-type--theme-dark-type h6 a,
.h-type.h-type--theme-dark-type .h6 a,
.h-type.h-type--theme-dark-type a h6,
.h-type.h-type--theme-dark-type a.h6 {}

.h-type.h-type--theme-dark-type h6 a:hover,
.h-type.h-type--theme-dark-type .h6 a:hover,
.h-type.h-type--theme-dark-type a:hover h6,
.h-type.h-type--theme-dark-type a:hover.h6 {}

.h-type.h-type--theme-light-type h6 a,
.h-type.h-type--theme-light-type .h6 a,
.h-type.h-type--theme-light-type a h6,
.h-type.h-type--theme-light-type a.h6 {}

.h-type.h-type--theme-light-type h6 a:hover,
.h-type.h-type--theme-light-type .h6 a:hover,
.h-type.h-type--theme-light-type a:hover h6,
.h-type.h-type--theme-light-type a:hover.h6 {}

.h-type p a,
.h-type .p a,
.h-type a p,
.h-type a.p {
}

.h-type p a:hover,
.h-type .p a:hover,
.h-type a:hover p,
.h-type a:hover.p {}

.h-type.h-type--theme-dark-type p a,
.h-type.h-type--theme-dark-type .p a,
.h-type.h-type--theme-dark-type a p,
.h-type.h-type--theme-dark-type a.p {}

.h-type.h-type--theme-dark-type p a:hover,
.h-type.h-type--theme-dark-type .p a:hover,
.h-type.h-type--theme-dark-type a:hover p,
.h-type.h-type--theme-dark-type a:hover.p {}

.h-type.h-type--theme-light-type p a,
.h-type.h-type--theme-light-type .p a,
.h-type.h-type--theme-light-type a p,
.h-type.h-type--theme-light-type a.p {}

.h-type.h-type--theme-light-type p a:hover,
.h-type.h-type--theme-light-type .p a:hover,
.h-type.h-type--theme-light-type a:hover p,
.h-type.h-type--theme-light-type a:hover.p {}

.h-type blockquote a,
.h-type .blockquote a,
.h-type a blockquote,
.h-type a.blockquote {}

.h-type blockquote a:hover,
.h-type .blockquote a:hover,
.h-type a:hover blockquote,
.h-type a:hover.blockquote {}

.h-type.h-type--theme-dark-type blockquote a,
.h-type.h-type--theme-dark-type .blockquote a,
.h-type.h-type--theme-dark-type a blockquote,
.h-type.h-type--theme-dark-type a.blockquote {}

.h-type.h-type--theme-dark-type blockquote a:hover,
.h-type.h-type--theme-dark-type .blockquote a:hover,
.h-type.h-type--theme-dark-type a:hover blockquote,
.h-type.h-type--theme-dark-type a:hover.blockquote {}

.h-type.h-type--theme-light-type blockquote a,
.h-type.h-type--theme-light-type .blockquote a,
.h-type.h-type--theme-light-type a blockquote,
.h-type.h-type--theme-light-type a.blockquote {}

.h-type.h-type--theme-light-type blockquote a:hover,
.h-type.h-type--theme-light-type .blockquote a:hover,
.h-type.h-type--theme-light-type a:hover blockquote,
.h-type.h-type--theme-light-type a:hover.blockquote {}

.h-type ol a,
.h-type a ol {}

.h-type ol a:hover,
.h-type a:hover ol {}

.h-type.h-type--theme-dark-type ol a,
.h-type.h-type--theme-dark-type a ol {}

.h-type.h-type--theme-dark-type ol a:hover,
.h-type.h-type--theme-dark-type a:hover ol {}

.h-type.h-type--theme-light-type ol a,
.h-type.h-type--theme-light-type a ol {}

.h-type.h-type--theme-light-type ol a:hover,
.h-type.h-type--theme-light-type a:hover ol {}

.h-type ul a,
.h-type a ul {}

.h-type ul a:hover,
.h-type a:hover ul {}

.h-type.h-type--theme-dark-type ul a,
.h-type.h-type--theme-dark-type a ul {}

.h-type.h-type--theme-dark-type ul a:hover,
.h-type.h-type--theme-dark-type a:hover ul {}

.h-type.h-type--theme-light-type ul a,
.h-type.h-type--theme-light-type a ul {}

.h-type.h-type--theme-light-type ul a:hover,
.h-type.h-type--theme-light-type a:hover ul {}

.h-type li a,
.h-type a li {}

.h-type li a:hover,
.h-type a:hover li {}

.h-type.h-type--theme-dark-type li a,
.h-type.h-type--theme-dark-type a li {}

.h-type.h-type--theme-dark-type li a:hover,
.h-type.h-type--theme-dark-type a:hover li {}

.h-type.h-type--theme-light-type li a,
.h-type.h-type--theme-light-type a li {}

.h-type.h-type--theme-light-type li a:hover,
.h-type.h-type--theme-light-type a:hover li {}

.h-type hr a,
.h-type a hr {}

.h-type hr a:hover,
.h-type a:hover hr {}

.h-type.h-type--theme-dark-type hr a,
.h-type.h-type--theme-dark-type a hr {}

.h-type.h-type--theme-dark-type hr a:hover,
.h-type.h-type--theme-dark-type a:hover hr {}

.h-type.h-type--theme-light-type hr a,
.h-type.h-type--theme-light-type a hr {}

.h-type.h-type--theme-light-type hr a:hover,
.h-type.h-type--theme-light-type a:hover hr {}

.h-type pre a,
.h-type a pre {}

.h-type pre a:hover,
.h-type a:hover pre {}

.h-type.h-type--theme-dark-type pre a,
.h-type.h-type--theme-dark-type a pre {}

.h-type.h-type--theme-dark-type pre a:hover,
.h-type.h-type--theme-dark-type a:hover pre {}

.h-type.h-type--theme-light-type pre a,
.h-type.h-type--theme-light-type a pre {}

.h-type.h-type--theme-light-type pre a:hover,
.h-type.h-type--theme-light-type a:hover pre {}

.h-type .large a,
.h-type a .large {}

.h-type .large a:hover,
.h-type a:hover .large {}

.h-type.h-type--theme-dark-type .large a,
.h-type.h-type--theme-dark-type a .large {}

.h-type.h-type--theme-dark-type .large a:hover,
.h-type.h-type--theme-dark-type a:hover .large {}

.h-type.h-type--theme-light-type .large a,
.h-type.h-type--theme-light-type a .large {}

.h-type.h-type--theme-light-type .large a:hover,
.h-type.h-type--theme-light-type a:hover .large {}

.h-type .small a,
.h-type a .small {
}

.h-type .small a:hover,
.h-type a:hover .small {}

.h-type.h-type--theme-dark-type .small a,
.h-type.h-type--theme-dark-type a .small {}

.h-type.h-type--theme-dark-type .small a:hover,
.h-type.h-type--theme-dark-type a:hover .small {}

.h-type.h-type--theme-light-type .small a,
.h-type.h-type--theme-light-type a .small {}

.h-type.h-type--theme-light-type .small a:hover,
.h-type.h-type--theme-light-type a:hover .small {}

/* Spacing
============================================================= */

.h-type * + * {margin-top: 2rem;}

.h-type * + br {margin-top: 0;}

.h-type h1 + h1,
.h-type .h1 + h1,
.h-type h1 + .h1,
.h-type .h1 + .h1 {}
.h-type h1 + h2,
.h-type .h1 + h2,
.h-type h1 + .h2,
.h-type .h1 + .h2 {}
.h-type h1 + h3,
.h-type .h1 + h3,
.h-type h1 + .h3,
.h-type .h1 + .h3 {margin-top: 1rem;}
.h-type h1 + h4,
.h-type .h1 + h4,
.h-type h1 + .h4,
.h-type .h1 + .h4 {}
.h-type h1 + h5,
.h-type .h1 + h5,
.h-type h1 + .h5,
.h-type .h1 + .h5 {}
.h-type h1 + h6,
.h-type .h1 + h6,
.h-type h1 + .h6,
.h-type .h1 + .h6 {}
.h-type h1 + p,
.h-type .h1 + p,
.h-type h1 + .p,
.h-type .h1 + .p {margin-top: 1rem;}
.h-type h1 + blockquote,
.h-type .h1 + blockquote,
.h-type h1 + .blockquote,
.h-type .h1 + .blockquote {}
.h-type h1 + ol,
.h-type .h1 + ol {}
.h-type h1 + ul,
.h-type .h1 + ul {}
.h-type h1 + li,
.h-type .h1 + li {}
.h-type h1 + hr,
.h-type .h1 + hr {}
.h-type h1 + pre,
.h-type .h1 + pre {}
.h-type h1 + .large,
.h-type .h1 + .large {margin-top: 1rem;}
.h-type h1 + .small,
.h-type .h1 + .small {margin-top: 1rem;}

.h-type h2 + h1,
.h-type .h2 + h1,
.h-type h2 + .h1,
.h-type .h2 + .h1 {}
.h-type h2 + h2,
.h-type .h2 + h2,
.h-type h2 + .h2,
.h-type .h2 + .h2 {}
.h-type h2 + h3,
.h-type .h2 + h3,
.h-type h2 + .h3,
.h-type .h2 + .h3 {}
.h-type h2 + h4,
.h-type .h2 + h4,
.h-type h2 + .h4,
.h-type .h2 + .h4 {}
.h-type h2 + h5,
.h-type .h2 + h5,
.h-type h2 + .h5,
.h-type .h2 + .h5 {}
.h-type h2 + h6,
.h-type .h2 + h6,
.h-type h2 + .h6,
.h-type .h2 + .h6 {}
.h-type h2 + p,
.h-type .h2 + p,
.h-type h2 + .p,
.h-type .h2 + .p {margin-top: 1rem;}
.h-type h2 + blockquote,
.h-type .h2 + blockquote,
.h-type h2 + .blockquote,
.h-type .h2 + .blockquote {}
.h-type h2 + ol,
.h-type .h2 + ol {}
.h-type h2 + ul,
.h-type .h2 + ul {}
.h-type h2 + li,
.h-type .h2 + li {}
.h-type h2 + hr,
.h-type .h2 + hr {}
.h-type h2 + pre,
.h-type .h2 + pre {}
.h-type h2 + .large,
.h-type .h2 + .large {margin-top: 1rem;}
.h-type h2 + .small,
.h-type .h2 + .small {margin-top: 1rem;}

.h-type h3 + h1,
.h-type .h3 + h1,
.h-type h3 + .h1,
.h-type .h3 + .h1 {}
.h-type h3 + h2,
.h-type .h3 + h2,
.h-type h3 + .h2,
.h-type .h3 + .h2 {}
.h-type h3 + h3,
.h-type .h3 + h3,
.h-type h3 + .h3,
.h-type .h3 + .h3 {}
.h-type h3 + h4,
.h-type .h3 + h4,
.h-type h3 + .h4,
.h-type .h3 + .h4 {margin-top: 1.5rem;}
.h-type h3 + h5,
.h-type .h3 + h5,
.h-type h3 + .h5,
.h-type .h3 + .h5 {}
.h-type h3 + h6,
.h-type .h3 + h6,
.h-type h3 + .h6,
.h-type .h3 + .h6 {}
.h-type h3 + p,
.h-type .h3 + p,
.h-type h3 + .p,
.h-type .h3 + .p {margin-top: .75rem;}
.h-type h3 + blockquote,
.h-type .h3 + blockquote,
.h-type h3 + .blockquote,
.h-type .h3 + .blockquote {}
.h-type h3 + ol,
.h-type .h3 + ol {}
.h-type h3 + ul,
.h-type .h3 + ul {}
.h-type h3 + li,
.h-type .h3 + li {}
.h-type h3 + hr,
.h-type .h3 + hr {}
.h-type h3 + pre,
.h-type .h3 + pre {}
.h-type h3 + .large,
.h-type .h3 + .large {margin-top: .75rem;}
.h-type h3 + .small,
.h-type .h3 + .small {margin-top: .75rem;}

.h-type h4 + h1,
.h-type .h4 + h1,
.h-type h4 + .h1,
.h-type .h4 + .h1 {}
.h-type h4 + h2,
.h-type .h4 + h2,
.h-type h4 + .h2,
.h-type .h4 + .h2 {}
.h-type h4 + h3,
.h-type .h4 + h3,
.h-type h4 + .h3,
.h-type .h4 + .h3 {}
.h-type h4 + h4,
.h-type .h4 + h4,
.h-type h4 + .h4,
.h-type .h4 + .h4 {margin-top: .25rem;}
.h-type h4 + h5,
.h-type .h4 + h5,
.h-type h4 + .h5,
.h-type .h4 + .h5 {}
.h-type h4 + h6,
.h-type .h4 + h6,
.h-type h4 + .h6,
.h-type .h4 + .h6 {}
.h-type h4 + p,
.h-type .h4 + p,
.h-type h4 + .p,
.h-type .h4 + .p {margin-top: .75rem;}
.h-type h4 + blockquote,
.h-type .h4 + blockquote,
.h-type h4 + .blockquote,
.h-type .h4 + .blockquote {}
.h-type h4 + ol,
.h-type .h4 + ol {}
.h-type h4 + ul,
.h-type .h4 + ul {}
.h-type h4 + li,
.h-type .h4 + li {}
.h-type h4 + hr,
.h-type .h4 + hr {}
.h-type h4 + pre,
.h-type .h4 + pre {}
.h-type h4 + .large,
.h-type .h4 + .large {margin-top: .75rem;}
.h-type h4 + .small,
.h-type .h4 + .small {margin-top: .75rem;}

.h-type h5 + h1,
.h-type .h5 + h1,
.h-type h5 + .h1,
.h-type .h5 + .h1 {}
.h-type h5 + h2,
.h-type .h5 + h2,
.h-type h5 + .h2,
.h-type .h5 + .h2 {}
.h-type h5 + h3,
.h-type .h5 + h3,
.h-type h5 + .h3,
.h-type .h5 + .h3 {}
.h-type h5 + h4,
.h-type .h5 + h4,
.h-type h5 + .h4,
.h-type .h5 + .h4 {}
.h-type h5 + h5,
.h-type .h5 + h5,
.h-type h5 + .h5,
.h-type .h5 + .h5 {}
.h-type h5 + h6,
.h-type .h5 + h6,
.h-type h5 + .h6,
.h-type .h5 + .h6 {}
.h-type h5 + p,
.h-type .h5 + p,
.h-type h5 + .p,
.h-type .h5 + .p {}
.h-type h5 + blockquote,
.h-type .h5 + blockquote,
.h-type h5 + .blockquote,
.h-type .h5 + .blockquote {}
.h-type h5 + ol,
.h-type .h5 + ol {}
.h-type h5 + ul,
.h-type .h5 + ul {}
.h-type h5 + li,
.h-type .h5 + li {}
.h-type h5 + hr,
.h-type .h5 + hr {}
.h-type h5 + pre,
.h-type .h5 + pre {}
.h-type h5 + .large,
.h-type .h5 + .large {}
.h-type h5 + .small,
.h-type .h5 + .small {}

.h-type h6 + h1,
.h-type .h6 + h1,
.h-type h6 + .h1,
.h-type .h6 + .h1 {}
.h-type h6 + h2,
.h-type .h6 + h2,
.h-type h6 + .h2,
.h-type .h6 + .h2 {}
.h-type h6 + h3,
.h-type .h6 + h3,
.h-type h6 + .h3,
.h-type .h6 + .h3 {}
.h-type h6 + h4,
.h-type .h6 + h4,
.h-type h6 + .h4,
.h-type .h6 + .h4 {}
.h-type h6 + h5,
.h-type .h6 + h5,
.h-type h6 + .h5,
.h-type .h6 + .h5 {}
.h-type h6 + h6,
.h-type .h6 + h6,
.h-type h6 + .h6,
.h-type .h6 + .h6 {}
.h-type h6 + p,
.h-type .h6 + p,
.h-type h6 + .p,
.h-type .h6 + .p {}
.h-type h6 + blockquote,
.h-type .h6 + blockquote,
.h-type h6 + .blockquote,
.h-type .h6 + .blockquote {}
.h-type h6 + ol,
.h-type .h6 + ol {}
.h-type h6 + ul,
.h-type .h6 + ul {}
.h-type h6 + li,
.h-type .h6 + li {}
.h-type h6 + hr,
.h-type .h6 + hr {}
.h-type h6 + pre,
.h-type .h6 + pre {}
.h-type h6 + .large,
.h-type .h6 + .large {}
.h-type h6 + .small,
.h-type .h6 + .small {}

.h-type p + h1,
.h-type .p + h1,
.h-type p + .h1,
.h-type .p + .h1 {}
.h-type p + h2,
.h-type .p + h2,
.h-type p + .h2,
.h-type .p + .h2 {}
.h-type p + h3,
.h-type .p + h3,
.h-type p + .h3,
.h-type .p + .h3 {}
.h-type p + h4,
.h-type .p + h4,
.h-type p + .h4,
.h-type .p + .h4 {}
.h-type p + h5,
.h-type .p + h5,
.h-type p + .h5,
.h-type .p + .h5 {}
.h-type p + h6,
.h-type .p + h6,
.h-type p + .h6,
.h-type .p + .h6 {}
.h-type p + p,
.h-type .p + p,
.h-type p + .p,
.h-type .p + .p {}
.h-type p + blockquote,
.h-type .p + blockquote,
.h-type p + .blockquote,
.h-type .p + .blockquote {}
.h-type p + ol,
.h-type .p + ol {}
.h-type p + ul,
.h-type .p + ul {}
.h-type p + li,
.h-type .p + li {}
.h-type p + hr,
.h-type .p + hr {}
.h-type p + pre,
.h-type .p + pre {}
.h-type p + .large,
.h-type .p + .large {}
.h-type p + .small,
.h-type .p + .small {}

.h-type blockquote + h1,
.h-type .blockquote + h1,
.h-type blockquote + .h1,
.h-type .blockquote + .h1 {}
.h-type blockquote + h2,
.h-type .blockquote + h2,
.h-type blockquote + .h2,
.h-type .blockquote + .h2 {}
.h-type blockquote + h3,
.h-type .blockquote + h3,
.h-type blockquote + .h3,
.h-type .blockquote + .h3 {}
.h-type blockquote + h4,
.h-type .blockquote + h4,
.h-type blockquote + .h4,
.h-type .blockquote + .h4 {}
.h-type blockquote + h5,
.h-type .blockquote + h5,
.h-type blockquote + .h5,
.h-type .blockquote + .h5 {}
.h-type blockquote + h6,
.h-type .blockquote + h6,
.h-type blockquote + .h6,
.h-type .blockquote + .h6 {}
.h-type blockquote + p,
.h-type .blockquote + p,
.h-type blockquote + .p,
.h-type .blockquote + .p {}
.h-type blockquote + blockquote,
.h-type .blockquote + blockquote,
.h-type blockquote + .blockquote,
.h-type .blockquote + .blockquote {}
.h-type blockquote + ol,
.h-type .blockquote + ol {}
.h-type blockquote + ul,
.h-type .blockquote + ul {}
.h-type blockquote + li,
.h-type .blockquote + li {}
.h-type blockquote + hr,
.h-type .blockquote + hr {}
.h-type blockquote + pre,
.h-type .blockquote + pre {}
.h-type blockquote + .large,
.h-type .blockquote + .large {}
.h-type blockquote + .small,
.h-type .blockquote + .small {}

.h-type ol + h1,
.h-type ol + .h1 {}
.h-type ol + h2,
.h-type ol + .h2 {}
.h-type ol + h3,
.h-type ol + .h3 {}
.h-type ol + h4,
.h-type ol + .h4 {}
.h-type ol + h5,
.h-type ol + .h5 {}
.h-type ol + h6,
.h-type ol + .h6 {}
.h-type ol + p,
.h-type ol + .p {}
.h-type ol + blockquote,
.h-type ol + .blockquote {}
.h-type ol + ol,
.h-type ol + ol {}
.h-type ol + ul,
.h-type ol + ul {}
.h-type ol + li,
.h-type ol + li {}
.h-type ol + hr,
.h-type ol + hr {}
.h-type ol + pre,
.h-type ol + pre {}
.h-type ol + .large,
.h-type ol + .large {}
.h-type ol + .small,
.h-type ol + .small {}

.h-type ul + h1,
.h-type ul + .h1 {}
.h-type ul + h2,
.h-type ul + .h2 {}
.h-type ul + h3,
.h-type ul + .h3 {}
.h-type ul + h4,
.h-type ul + .h4 {}
.h-type ul + h5,
.h-type ul + .h5 {}
.h-type ul + h6,
.h-type ul + .h6 {}
.h-type ul + p,
.h-type ul + .p {}
.h-type ul + blockquote,
.h-type ul + .blockquote {}
.h-type ul + ol,
.h-type ul + ol {}
.h-type ul + ul,
.h-type ul + ul {}
.h-type ul + li,
.h-type ul + li {}
.h-type ul + hr,
.h-type ul + hr {}
.h-type ul + pre,
.h-type ul + pre {}
.h-type ul + .large,
.h-type ul + .large {}
.h-type ul + .small,
.h-type ul + .small {}

.h-type li + h1,
.h-type li + .h1 {}
.h-type li + h2,
.h-type li + .h2 {}
.h-type li + h3,
.h-type li + .h3 {}
.h-type li + h4,
.h-type li + .h4 {}
.h-type li + h5,
.h-type li + .h5 {}
.h-type li + h6,
.h-type li + .h6 {}
.h-type li + p,
.h-type li + .p {}
.h-type li + blockquote,
.h-type li + .blockquote {}
.h-type li + ol,
.h-type li + ol {}
.h-type li + ul,
.h-type li + ul {}
.h-type li + li,
.h-type li + li {}
.h-type li + hr,
.h-type li + hr {}
.h-type li + pre,
.h-type li + pre {}
.h-type li + .large,
.h-type li + .large {}
.h-type li + .small,
.h-type li + .small {}

.h-type hr + h1,
.h-type hr + .h1 {}
.h-type hr + h2,
.h-type hr + .h2 {}
.h-type hr + h3,
.h-type hr + .h3 {}
.h-type hr + h4,
.h-type hr + .h4 {}
.h-type hr + h5,
.h-type hr + .h5 {}
.h-type hr + h6,
.h-type hr + .h6 {}
.h-type hr + p,
.h-type hr + .p {}
.h-type hr + blockquote,
.h-type hr + .blockquote {}
.h-type hr + ol,
.h-type hr + ol {}
.h-type hr + ul,
.h-type hr + ul {}
.h-type hr + li,
.h-type hr + li {}
.h-type hr + hr,
.h-type hr + hr {}
.h-type hr + pre,
.h-type hr + pre {}
.h-type hr + .large,
.h-type hr + .large {}
.h-type hr + .small,
.h-type hr + .small {}

.h-type pre + h1,
.h-type pre + .h1 {}
.h-type pre + h2,
.h-type pre + .h2 {}
.h-type pre + h3,
.h-type pre + .h3 {}
.h-type pre + h4,
.h-type pre + .h4 {}
.h-type pre + h5,
.h-type pre + .h5 {}
.h-type pre + h6,
.h-type pre + .h6 {}
.h-type pre + p,
.h-type pre + .p {}
.h-type pre + blockquote,
.h-type pre + .blockquote {}
.h-type pre + ol,
.h-type pre + ol {}
.h-type pre + ul,
.h-type pre + ul {}
.h-type pre + li,
.h-type pre + li {}
.h-type pre + hr,
.h-type pre + hr {}
.h-type pre + pre,
.h-type pre + pre {}
.h-type pre + .large,
.h-type pre + .large {}
.h-type pre + .small,
.h-type pre + .small {}

.h-type .large + h1,
.h-type .large + .h1 {}
.h-type .large + h2,
.h-type .large + .h2 {}
.h-type .large + h3,
.h-type .large + .h3 {}
.h-type .large + h4,
.h-type .large + .h4 {}
.h-type .large + h5,
.h-type .large + .h5 {}
.h-type .large + h6,
.h-type .large + .h6 {}
.h-type .large + p,
.h-type .large + .p {}
.h-type .large + blockquote,
.h-type .large + .blockquote {}
.h-type .large + ol,
.h-type .large + ol {}
.h-type .large + ul,
.h-type .large + ul {}
.h-type .large + li,
.h-type .large + li {}
.h-type .large + hr,
.h-type .large + hr {}
.h-type .large + pre,
.h-type .large + pre {}
.h-type .large + .large,
.h-type .large + .large {}
.h-type .large + .small,
.h-type .large + .small {}

.h-type .small + h1,
.h-type .small + .h1 {}
.h-type .small + h2,
.h-type .small + .h2 {}
.h-type .small + h3,
.h-type .small + .h3 {}
.h-type .small + h4,
.h-type .small + .h4 {}
.h-type .small + h5,
.h-type .small + .h5 {}
.h-type .small + h6,
.h-type .small + .h6 {}
.h-type .small + p,
.h-type .small + .p {}
.h-type .small + blockquote,
.h-type .small + .blockquote {}
.h-type .small + ol,
.h-type .small + ol {}
.h-type .small + ul,
.h-type .small + ul {}
.h-type .small + li,
.h-type .small + li {}
.h-type .small + hr,
.h-type .small + hr {}
.h-type .small + pre,
.h-type .small + pre {}
.h-type .small + .large,
.h-type .small + .large {}
.h-type .small + .small,
.h-type .small + .small {}
