@import '../../css/vars';

$CNAME: 'c-block-header';

.#{$CNAME} {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: $z-index-overlay-content;

	&.m-hide-logo{
		visibility:hidden;
	}
}

.#{$CNAME}::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 230%;
	background: linear-gradient(180deg, rgba(color('lapis-2'), .99) 80%, transparent);
	opacity: 0;
	transition: opacity base-style-value('transition-duration-extra-fast') ease;
	pointer-events: none;
}

.#{$CNAME}.min-48rem::before {
	background: linear-gradient(180deg, rgba(color('lapis-2'), .8) 50%, transparent);

	@media (max-width: 991px) {
		background: linear-gradient(180deg, rgba(color('lapis-2'), .99) 80%, transparent);
	}
}

.#{$CNAME}.m-menu-open::before {
	opacity: 1;
}

.#{$CNAME}--bar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem 0;
}


.#{$CNAME}{
	&.m-menu-open{
		.#{$CNAME}--menu.mobile{
			display: block;
		}
	}
	.#{$CNAME}--bar .#{$CNAME}--menu-desktop {
		display: block;

		.site--container {
			max-width: none!important;
		}

		@media (max-width: 991px) {
			display: none;
		}
	}
}


.#{$CNAME}.min-48rem .#{$CNAME}--bar {
	padding: 2rem 0;
}

.#{$CNAME}--logo {
	transition: opacity 250ms ease;
}

.#{$CNAME}.m-hide-logo:not(.m-menu-open) .#{$CNAME}--logo {
	opacity: 0;
	pointer-events: none;
}

.#{$CNAME}--logo svg {
	max-width: 8.75rem;
}

.#{$CNAME}.min-48rem .#{$CNAME}--logo svg {
	max-width: none;
}

.#{$CNAME}--logo path {
	fill: color('oxeye');
	transition: fill base-style-value('transition-duration-extra-fast') ease;
}

.#{$CNAME}--logo:hover path {
	fill: color('ice');
}

// Menu

.#{$CNAME}--menu {
	position: relative;
	display: none;
}

.#{$CNAME}--menu-wrapper {
	display: flex;
}

.#{$CNAME}--items {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.#{$CNAME}.min-48rem .#{$CNAME}--items {
	flex-direction: row;
	justify-content: space-around;


}

.#{$CNAME} .#{$CNAME}--items {
	@media (max-width: 991px) {
		flex-direction: column;
	}
}

.#{$CNAME}--item {}

.#{$CNAME}--button {
	display: flex;
	@include font-style('muli-600-n');
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: .0625rem;
	text-transform: uppercase;
	color: color('ice');
	transition: color base-style-value('transition-duration-extra-fast') ease;
	margin: 0 10px;

	@media (max-width: 1280px) and (min-width: 991px){
		margin: 0;
		font-size: .8rem;
		padding: 0.75rem 0.5rem;
	}

	&.text-lowercase {
		text-transform: none;
	}
	&.menu-btn {
		font-size: 12px;
		font-weight: 800;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: 1.2px;
		text-align: right;
		color: #13284c;
		padding: 14px 15px 13px 19px;
		background-color: color('ice');
		margin-left: 18px;
		margin-right: 0;

		&.m-current {
			color: #13284c;

			&:hover{
				color: #fff;
			}
		}


		@media (max-width: 991px) {
			margin-left: 0;
			margin-right: 0;
			margin-top: 12px;
		}
	}
}

.#{$CNAME}--button.m-current {
	color: color('oxeye');

	+ .submenu-opener{
		&:before,
		&:after{
			background-color: #fff!important;
		}
	}

	&.submenu-button{
		color: #96d5ea!important;
	}
}

.#{$CNAME}--button:hover {
	color: color('oxeye');
}


.#{$CNAME}--item{
	&.has-submenu {
		position: relative;
		margin-right: 12px;

		@media (max-width: 1280px) and (min-width: 991px){
			margin-right: 0;
			padding-right: 30px;

			.#{$CNAME}--button{
				padding-right: 0;
			}

			.submenu-opener {
				right: 12px!important;
			}

			.c-block-header-submenu-wrapper {
				padding-right: 30px;
			}
		}

		.submenu-opener {
			position: absolute;
			width: 8px;
			height: 8px;
			transform: rotate(45deg);
			right: 6px;
			top: 0;
			bottom: 0;
			margin: auto 0;

			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 1px;
				background-color: #96d5ea;
				bottom: 0;
				border-radius: 50px;
				transition: background-color .3s ease-in-out;
			}

			&:after {
				content: '';
				position: absolute;
				width: 1px;
				height: 100%;
				background-color: #96d5ea;
				right: 0;
				border-radius: 50px;
				transition: background-color .3s ease-in-out;
			}
		}

		&:hover{
			>a {
				color: #ffffff;
			}
			>.#{$CNAME}-submenu-wrapper {
				opacity: 1;
				visibility: visible;
			}

			.submenu-opener {
				transform: rotate(225deg);
				top: 8px;

				&:before,
				&:after{
					background-color: #fff;
				}
			}
		}
	}
}



.#{$CNAME}-submenu-wrapper {
	position: absolute;
	min-width: 200px;
	right: 0;
	opacity: 0;
	visibility: hidden;
	transition: opacity .3s ease-in-out;

	.#{$CNAME}--submenu-item.sub-item {
		text-align: right;

		.submenu-button {
			text-transform: none;
			letter-spacing: normal;
			justify-content: right;
			padding-left: 0;
			margin-left: 0;
			font-weight: 400;
			color: #fff;

			&:hover{
				color: #96d5ea;
			}
		}
	}
}


.#{$CNAME}--menu.mobile{
	.c-block-header--menu-wrapper{
		.c-block-header--items {
			display: block;
			max-width: 247px;
			margin: auto;
			padding: 0;

			.c-block-header--item.item {
				text-align: center;
				margin: 0;

				&:hover{
					.c-block-header--button {
						color: #fff;

					}

					.submenu-opener {
						&:before,
						&:after {
							background-color: #fff;
						}
					}
				}



				.c-block-header--button {
					padding: 0.89rem;
					text-align: center;
					display: inline-block;
					margin: 0;

					&.menu-btn {
						margin-left: 0;
						margin-right: 0;
						margin-top: 21px;
						padding: 14px 15px 13px 19px;
						line-height: normal;
					}
				}




				&.has-submenu {
					.c-block-header--button {
						padding-right: 0;
						margin-right: calc(0.89rem + 15px);
						margin-left: 16px;
					}
					&.active{
						.c-block-header--button {
							color: #fff;
						}
					}

					.submenu-opener{
						position: relative;
						width: 25px;
						height: 25px;
						transform: rotate(45deg);
						right: 30px;
						top: -7px;
						bottom: 0;
						margin: auto 0;
						display: inline-block;
						cursor: pointer;
						-webkit-tap-highlight-color: rgba(0,0,0,0);

						&:before{
							right: 0;
							height: 1px;
							width: 8px;
						}
						&:after{
							width: 1px;
							height: 8px;
							bottom: 0;
						}

						&.active{
							top: 0;

							&:before,
							&:after{
								background-color: #fff;
							}
							&:before{
								bottom: 7px;
							}
							&:after{
								right: 7px;
							}

						}
					}

					.c-block-header-submenu-wrapper {
						position: relative;
						min-width: 200px;
						right: 0;
						display: none;
						opacity: 1;
						visibility: visible;

						.c-block-header--submenu-item.sub-item {
							text-align: center;

							.submenu-button{
								padding: 0.75rem 0;
								margin: 0;


							}
						}
					}
				}
			}
		}
	}
}


.m-hide-logo .c-block-header-submenu-wrapper {
	opacity: 0!important;
	visibility: hidden!important;
}

main.site--main {
	.c-page-homepage{
		.c-block-ocean--text-container {
			.c-block-ocean--text-wrapper{
				@media (max-width: 1680px) and (min-width: 991px){
					padding-top: 300px!important;
				}

			}
		}
	}
}

.c-block-header {
	@media (min-width: 991px) {
		&:before {
			display: none!important;
		}
		.c-block-header--menu.mobile .c-block-header--menu-wrapper {
			display: none !important;
		}
	}
}