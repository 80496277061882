@import '../../css/vars';

$CNAME: 'c-block-image-and-text';

.#{$CNAME}.m-vertical-align-top .#{$CNAME}--content {
	align-self: flex-start;
}

.#{$CNAME}.m-vertical-align-center .#{$CNAME}--content {
	align-self: center;
}

.#{$CNAME}.m-vertical-align-bottom .#{$CNAME}--content {
	align-self: flex-end;
}

.#{$CNAME}--button {
	margin-top: 2rem;
	text-align: right;
}
