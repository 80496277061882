@import '../../css/vars';

$CNAME: 'c-block-ocean';

.#{$CNAME} {
	position: relative;
}

/* Ocean Video
----------------------------- */

.#{$CNAME}--ocean-video-container {
	position: relative;
	overflow-x: hidden;
}

.#{$CNAME}--ocean-video-container::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
	background: linear-gradient(0deg, rgba(color('ocean-1'), 1), rgba(color('ocean-1'), 0));
}

.#{$CNAME}--ocean-video {
	width: calc(100% + 32rem);
	margin: 0 -16rem;
}

.#{$CNAME}.min-32rem .#{$CNAME}--ocean-video {
	width: calc(100% +24rem);
	margin: 0 -12rem;
}

.#{$CNAME}.min-48rem .#{$CNAME}--ocean-video {
	width: calc(100% + 16rem);
	margin: 0 -8rem;
}

.#{$CNAME}.min-64rem .#{$CNAME}--ocean-video {
	width: calc(100% + 8rem);
	margin: 0 -4rem;
}

.#{$CNAME}.min-80rem .#{$CNAME}--ocean-video {
	width: 100%;
	margin: 0;
}

/* Non-Ocean Video
----------------------------- */

.#{$CNAME}--video-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
	transition: opacity base-style-value('transition-duration-extra-slow') ease;
	opacity: 0;
	z-index: $z-index-overlay;
}

.#{$CNAME}--video-container.m-visible {
	pointer-events: auto;
	opacity: 1;
}

.#{$CNAME}--video {
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* Video Play Button
----------------------------- */

.#{$CNAME}--video-play-button {
	width: 4.6875rem;
	height: 4.6875rem;
	cursor: pointer;
	margin-top: 1.75rem;
}

.#{$CNAME}--video-play-button [data-element='border'],
.#{$CNAME}--video-play-button [data-element='play'] {
	fill: none;
}

.#{$CNAME}--video-play-button [data-element='border'] {
	stroke: color('ice');
}

.#{$CNAME}--video-play-button [data-element='play'] {
	stroke: color('oxeye');
}

.#{$CNAME}--video-play-button:hover [data-element='border'] {
	fill: color('ice');
}

.#{$CNAME}--video-play-button:hover [data-element='play'] {
	stroke: color('lapis');
	fill: color('lapis');
}

/* Text Container
----------------------------- */

.#{$CNAME}--text-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 200%;
}

.#{$CNAME}--text-wrapper {
	position: sticky;
	top: 0;
	margin: 0 auto;
	width: 100%;
	max-width: 40rem;
	text-align: center;
	z-index: $z-index-page;
	opacity: 0;
	pointer-events: none;
	transition: opacity base-style-value('transition-duration-extra-slow') ease;
}

.#{$CNAME}--text-wrapper.m-visible {
	opacity: 1;
	pointer-events: auto;
}

/* Video Close Button
----------------------------- */

.#{$CNAME}--video-close-button {
	position: absolute;
	width: 4.6875rem;
	height: 4.6875rem;
	bottom: 1.5rem;
	left: calc(50% - 2.34375rem);
	cursor: pointer;
}

.#{$CNAME}--video-close-button [data-element='border'],
.#{$CNAME}--video-close-button [data-element='line'] {
	fill: none;
}

.#{$CNAME}--video-close-button [data-element='border'] {
	stroke: color('ice');
}

.#{$CNAME}--video-close-button [data-element='line'] {
	stroke: color('oxeye');
}

.#{$CNAME}--video-close-button:hover [data-element='border'] {
	fill: color('ice');
}

.#{$CNAME}--video-close-button:hover [data-element='line'] {
	stroke: color('lapis');
}


/* ROTATING TEXT
-----------------------------*/
.#{$CNAME}--text-container{

	.#{$CNAME}--text-wrapper{
		max-width: 860px !important;

		@media (max-width:1024px){
			padding-top: 240px !important;
		}

		@media (max-width:767px){
			padding-top:189px !important;
		}

		@media screen and (max-width: 37px), 
	       screen and (max-height: 667px) {
	       padding-top:130px !important;
		}
	}

	.c-base-primary-button {
	    margin-top:50px;

	    @media (max-width:980px){
	    	margin-top:30px;
	    }
	}

	.c-base-text.h-type{
		h1{
			padding-bottom: 30px;

			@media (max-width:980px){
				padding-bottom:20px;
			}

			@media (max-width:767px){
				font-size: 1.5rem;
				line-height: normal;
			}
		}
	}

	p.large{
		@media (max-width:767px){
		    font-size: 1rem;
    		line-height: 1.75rem;
		}
	}

	.c-block-ocean--icons{
		padding-top:50px;

		@media (max-width:375px){
			padding-top:30px;
		}
	}
}

@media (max-width:1024px){
	/*.c-block-ocean--text-wrapper{
		transition: opacity .5s ease-out !important;
	        
		&.text-fade-in{
			transition: opacity .5s ease-in !important;
			opacity: 1 !important;
		}
	}*/
}