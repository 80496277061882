@import '../../css/vars';

$CNAME: 'c-block-image-and-text-rows';

.#{$CNAME} {}

.#{$CNAME}--divider {
	padding: 4.5rem 1.5rem;
}

.#{$CNAME}--divider::before {
	content: '';
	display: block;
	width: 100%;
	height: .0625rem;
	background: repeating-linear-gradient(
		90deg,
		color('water'),
		color('water') .375rem,
		transparent .375rem,
		transparent .75rem
	);
}
