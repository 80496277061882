@import '../../css/vars';

$CNAME: 'c-page-conservation-entry';

.#{$CNAME} {

}

.#{$CNAME}--content {
	margin-top: 2rem;
}

@media (min-width: 48em) {
	.#{$CNAME}--content {
		margin-top: 4rem;
	}
}

.#{$CNAME}--cta {
	display: flex;
	justify-content: space-between;
	max-width: calc(64rem + 3rem); // 48rem is h-type max width, 3rem is p-full
}
