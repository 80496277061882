.row {
	display: flex;
	flex-flow: row wrap;
}

.col {
	display: block;
	flex: 1 1 0.0001px;
	width: auto;
}

/* Extra-Small (>= 0px)
----------------------------- */

.row > .col.xs-0 {display: none}

.row > .col.xs-1,
.row > .col.xs-2,
.row > .col.xs-3,
.row > .col.xs-4,
.row > .col.xs-5,
.row > .col.xs-6,
.row > .col.xs-7,
.row > .col.xs-8,
.row > .col.xs-9,
.row > .col.xs-10,
.row > .col.xs-11,
.row > .col.xs-12,
.row > .col.xs-min,
.row > .col.xs-max {display: block}

.row > .col.xs-1,
.row > .col.xs-2,
.row > .col.xs-3,
.row > .col.xs-4,
.row > .col.xs-5,
.row > .col.xs-6,
.row > .col.xs-7,
.row > .col.xs-8,
.row > .col.xs-9,
.row > .col.xs-10,
.row > .col.xs-11,
.row > .col.xs-12,
.row > .col.xs-min {flex: none}

.row > .col.xs-min,
.row > .col.xs-max {width: auto}

.row > .col.xs-1 {width: 8.3333%}

.row > .col.xs-2 {width: 16.6667%}

.row > .col.xs-3 {width: 25%}

.row > .col.xs-4 {width: 33.3333%}

.row > .col.xs-5 {width: 41.6667%}

.row > .col.xs-6 {width: 50%}

.row > .col.xs-7 {width: 58.3333%}

.row > .col.xs-8 {width: 66.6667%}

.row > .col.xs-9 {width: 75%}

.row > .col.xs-10 {width: 83.3333%}

.row > .col.xs-11 {width: 91.6667%}

.row > .col.xs-12 {width: 100%}

.row > .col.xs-max {flex: 1 1 .00000001px} /* 1 */

/* Small (>= 512px)
----------------------------- */

.row.min-32rem > .col.sm-0 {display: none}

.row.min-32rem > .col.sm-1,
.row.min-32rem > .col.sm-2,
.row.min-32rem > .col.sm-3,
.row.min-32rem > .col.sm-4,
.row.min-32rem > .col.sm-5,
.row.min-32rem > .col.sm-6,
.row.min-32rem > .col.sm-7,
.row.min-32rem > .col.sm-8,
.row.min-32rem > .col.sm-9,
.row.min-32rem > .col.sm-10,
.row.min-32rem > .col.sm-11,
.row.min-32rem > .col.sm-12,
.row.min-32rem > .col.sm-min,
.row.min-32rem > .col.sm-max {display: block}

.row.min-32rem > .col.sm-1,
.row.min-32rem > .col.sm-2,
.row.min-32rem > .col.sm-3,
.row.min-32rem > .col.sm-4,
.row.min-32rem > .col.sm-5,
.row.min-32rem > .col.sm-6,
.row.min-32rem > .col.sm-7,
.row.min-32rem > .col.sm-8,
.row.min-32rem > .col.sm-9,
.row.min-32rem > .col.sm-10,
.row.min-32rem > .col.sm-11,
.row.min-32rem > .col.sm-12,
.row.min-32rem > .col.sm-min {flex: none}

.row.min-32rem > .col.sm-min,
.row.min-32rem > .col.sm-max {width: auto}

.row.min-32rem > .col.sm-1 {width: 8.3333%}

.row.min-32rem > .col.sm-2 {width: 16.6667%}

.row.min-32rem > .col.sm-3 {width: 25%}

.row.min-32rem > .col.sm-4 {width: 33.3333%}

.row.min-32rem > .col.sm-5 {width: 41.6667%}

.row.min-32rem > .col.sm-6 {width: 50%}

.row.min-32rem > .col.sm-7 {width: 58.3333%}

.row.min-32rem > .col.sm-8 {width: 66.6667%}

.row.min-32rem > .col.sm-9 {width: 75%}

.row.min-32rem > .col.sm-10 {width: 83.3333%}

.row.min-32rem > .col.sm-11 {width: 91.6667%}

.row.min-32rem > .col.sm-12 {width: 100%}

.row.min-32rem > .col.sm-max {flex: 1 1 .00000001px} /* 1 */

/* Medium (>= 768px)
----------------------------- */

.row.min-48rem > .col.md-0 {display: none}

.row.min-48rem > .col.md-1,
.row.min-48rem > .col.md-2,
.row.min-48rem > .col.md-3,
.row.min-48rem > .col.md-4,
.row.min-48rem > .col.md-5,
.row.min-48rem > .col.md-6,
.row.min-48rem > .col.md-7,
.row.min-48rem > .col.md-8,
.row.min-48rem > .col.md-9,
.row.min-48rem > .col.md-10,
.row.min-48rem > .col.md-11,
.row.min-48rem > .col.md-12,
.row.min-48rem > .col.md-min,
.row.min-48rem > .col.md-max {display: block}

.row.min-48rem > .col.md-1,
.row.min-48rem > .col.md-2,
.row.min-48rem > .col.md-3,
.row.min-48rem > .col.md-4,
.row.min-48rem > .col.md-5,
.row.min-48rem > .col.md-6,
.row.min-48rem > .col.md-7,
.row.min-48rem > .col.md-8,
.row.min-48rem > .col.md-9,
.row.min-48rem > .col.md-10,
.row.min-48rem > .col.md-11,
.row.min-48rem > .col.md-12,
.row.min-48rem > .col.md-min {flex: none}

.row.min-48rem > .col.md-min,
.row.min-48rem > .col.md-max {width: auto}

.row.min-48rem > .col.md-1 {width: 8.3333%}

.row.min-48rem > .col.md-2 {width: 16.6667%}

.row.min-48rem > .col.md-3 {width: 25%}

.row.min-48rem > .col.md-4 {width: 33.3333%}

.row.min-48rem > .col.md-5 {width: 41.6667%}

.row.min-48rem > .col.md-6 {width: 50%}

.row.min-48rem > .col.md-7 {width: 58.3333%}

.row.min-48rem > .col.md-8 {width: 66.6667%}

.row.min-48rem > .col.md-9 {width: 75%}

.row.min-48rem > .col.md-10 {width: 83.3333%}

.row.min-48rem > .col.md-11 {width: 91.6667%}

.row.min-48rem > .col.md-12 {width: 100%}

.row.min-48rem > .col.md-max {flex: 1 1 .00000001px} /* 1 */

/* Large (>= 1024px)
----------------------------- */

.row.min-64rem > .col.lg-0 {display: none}

.row.min-64rem > .col.lg-1,
.row.min-64rem > .col.lg-2,
.row.min-64rem > .col.lg-3,
.row.min-64rem > .col.lg-4,
.row.min-64rem > .col.lg-5,
.row.min-64rem > .col.lg-6,
.row.min-64rem > .col.lg-7,
.row.min-64rem > .col.lg-8,
.row.min-64rem > .col.lg-9,
.row.min-64rem > .col.lg-10,
.row.min-64rem > .col.lg-11,
.row.min-64rem > .col.lg-12,
.row.min-64rem > .col.lg-min,
.row.min-64rem > .col.lg-max {display: block}

.row.min-64rem > .col.lg-1,
.row.min-64rem > .col.lg-2,
.row.min-64rem > .col.lg-3,
.row.min-64rem > .col.lg-4,
.row.min-64rem > .col.lg-5,
.row.min-64rem > .col.lg-6,
.row.min-64rem > .col.lg-7,
.row.min-64rem > .col.lg-8,
.row.min-64rem > .col.lg-9,
.row.min-64rem > .col.lg-10,
.row.min-64rem > .col.lg-11,
.row.min-64rem > .col.lg-12,
.row.min-64rem > .col.lg-min {flex: none}

.row.min-64rem > .col.lg-min,
.row.min-64rem > .col.lg-max {width: auto}

.row.min-64rem > .col.lg-1 {width: 8.3333%}

.row.min-64rem > .col.lg-2 {width: 16.6667%}

.row.min-64rem > .col.lg-3 {width: 25%}

.row.min-64rem > .col.lg-4 {width: 33.3333%}

.row.min-64rem > .col.lg-5 {width: 41.6667%}

.row.min-64rem > .col.lg-6 {width: 50%}

.row.min-64rem > .col.lg-7 {width: 58.3333%}

.row.min-64rem > .col.lg-8 {width: 66.6667%}

.row.min-64rem > .col.lg-9 {width: 75%}

.row.min-64rem > .col.lg-10 {width: 83.3333%}

.row.min-64rem > .col.lg-11 {width: 91.6667%}

.row.min-64rem > .col.lg-12 {width: 100%}

.row.min-64rem > .col.lg-max {flex: 1 1 .00000001px} /* 1 */

/* Extra-Large (>= 1280px)
----------------------------- */

.row.min-80rem > .col.xl-0 {display: none}

.row.min-80rem > .col.xl-1,
.row.min-80rem > .col.xl-2,
.row.min-80rem > .col.xl-3,
.row.min-80rem > .col.xl-4,
.row.min-80rem > .col.xl-5,
.row.min-80rem > .col.xl-6,
.row.min-80rem > .col.xl-7,
.row.min-80rem > .col.xl-8,
.row.min-80rem > .col.xl-9,
.row.min-80rem > .col.xl-10,
.row.min-80rem > .col.xl-11,
.row.min-80rem > .col.xl-12,
.row.min-80rem > .col.xl-min,
.row.min-80rem > .col.xl-max {display: block}

.row.min-80rem > .col.xl-1,
.row.min-80rem > .col.xl-2,
.row.min-80rem > .col.xl-3,
.row.min-80rem > .col.xl-4,
.row.min-80rem > .col.xl-5,
.row.min-80rem > .col.xl-6,
.row.min-80rem > .col.xl-7,
.row.min-80rem > .col.xl-8,
.row.min-80rem > .col.xl-9,
.row.min-80rem > .col.xl-10,
.row.min-80rem > .col.xl-11,
.row.min-80rem > .col.xl-12,
.row.min-80rem > .col.xl-min {flex: none}

.row.min-80rem > .col.xl-min,
.row.min-80rem > .col.xl-max {width: auto}

.row.min-80rem > .col.xl-1 {width: 8.3333%}

.row.min-80rem > .col.xl-2 {width: 16.6667%}

.row.min-80rem > .col.xl-3 {width: 25%}

.row.min-80rem > .col.xl-4 {width: 33.3333%}

.row.min-80rem > .col.xl-5 {width: 41.6667%}

.row.min-80rem > .col.xl-6 {width: 50%}

.row.min-80rem > .col.xl-7 {width: 58.3333%}

.row.min-80rem > .col.xl-8 {width: 66.6667%}

.row.min-80rem > .col.xl-9 {width: 75%}

.row.min-80rem > .col.xl-10 {width: 83.3333%}

.row.min-80rem > .col.xl-11 {width: 91.6667%}

.row.min-80rem > .col.xl-12 {width: 100%}

.row.min-80rem > .col.xl-max {flex: 1 1 .00000001px} /* 1 */

/* Element Query Utilities
----------------------------- */

.eq .xs-hidden {display: none}
.eq .xs-visible {display: block}

.eq.min-32rem .sm-hidden {display: none}
.eq.min-32rem .sm-visible {display: block}

.eq.min-48rem .md-hidden {display: none}
.eq.min-48rem .md-visible {display: block}

.eq.min-64rem .lg-hidden {display: none}
.eq.min-64rem .lg-visible {display: block}

.eq.min-80rem .xl-hidden {display: none}
.eq.min-80rem .xl-visible {display: block}

/**
 * 1. The `xs-max` class applies a `flex` value of `1 1 .00000001px` rather than `1 1 0` because
 * the minification system removes the unit for 0 values. This would normally be fine, except
 * Internet Explorer does not handle this properly.
 */
