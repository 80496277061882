@import '../../css/vars';

$CNAME: 'c-base-primary-button';

.#{$CNAME} {
	display: inline-block;
}

.#{$CNAME}.m-stretch {
	display: block;
	width: 100%;
}

/* Element
----------------------------- */

.#{$CNAME}--element {
	display: inline-block;
	padding: 1.25rem 3rem;
	@include font-style('sans-700-n');
	background-color: transparent;
	border: .125rem solid color('ice');
	position: relative;
	cursor: pointer;
	min-width: 13.75rem; // Buttons in comp seemed to always be at least this wide
	overflow: hidden;
	text-align: center;
}

.#{$CNAME}.m-stretch .#{$CNAME}--element {
	width: 100%;
}

/* Wave
----------------------------- */

$imageWidth: 6rem;
$imageHeight: 1.3125rem;

.#{$CNAME}--wave {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: color('ice');
	transform: translateY(100%) translateY($imageHeight);
	transition: transform 1000ms linear;
	pointer-events: none;
}

.#{$CNAME}--element:hover .#{$CNAME}--wave {
	transform: none;
}

.#{$CNAME}--wave::before {
	content: '';
	position: absolute;
	top: calc(-#{$imageHeight} + .0625rem); // Add one pixel to account for the border-bottom.
	left: 0;
	width: 100%;
	height: $imageHeight;
	background-image: url('/assets/ui/button-waves.png');
	background-size: $imageWidth $imageHeight;
	border-bottom: .0625rem solid color('ice'); // Add a border-bottom to prevent pixel gap in IE.
	animation: wave 500ms linear infinite;
}

@keyframes wave {
	0% { background-position-x: 0; }
	100% { background-position-x: $imageWidth; }
}

/* Label
----------------------------- */

.#{$CNAME}--label {
	position: relative;
	text-align: center;
	font-size: .875rem;
	line-height: 1.25;
	text-transform: uppercase;
	letter-spacing: .0875rem;
	color: color('oxeye');
	transition: color 250ms ease 250ms;
}

.#{$CNAME}--element:hover .#{$CNAME}--label {
	color: color('squid');
}
