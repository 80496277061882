@import '../../css/vars';

$CNAME: 'c-block-social-icon';


.#{$CNAME}--icons{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 56px;
}

.#{$CNAME}--icons .c-base-social-icon{
	margin:0 8px;
}