@import '../../css/vars';

$CNAME: 'c-base-tabs';

.#{$CNAME} {}

// Tabs

.#{$CNAME}--tabs {
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 0;
	margin-bottom: 3rem;
}

.#{$CNAME}--tab {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1 0 auto;
	padding: .75rem 1.5rem;
	@include font-style('muli-600-n');
	font-size: 1rem;
	line-height: 1.5rem;
	color: color('oxeye');
	letter-spacing: .0625rem;
	text-transform: uppercase;
	cursor: pointer;
}

.#{$CNAME}--tab:hover {
	color: color('ice');
}

.#{$CNAME}--tab[aria-selected='true'] {
	color: color('ice');
}

.#{$CNAME}--tab-arrow {
	margin-top: .25rem;
}

.#{$CNAME}--tab-arrow polygon {
	fill: transparent;
	stroke: color('ice');
	stroke-linejoin: round;
	stroke-width: .125rem;
}

.#{$CNAME}--tab:hover .#{$CNAME}--tab-arrow polygon {
	fill: color('ice');
	stroke: color('ice');
}

.#{$CNAME}--tab[aria-selected='true'] .#{$CNAME}--tab-arrow polygon {
	fill: color('ice');
	stroke: color('ice');
}

// Select

.#{$CNAME}--select-wrapper {
	position: relative;
}

.#{$CNAME}--select-element {
	@include font-style('muli-600-n');
	font-size: .875rem;
	line-height: 1.5rem;
	text-transform: uppercase;
	letter-spacing: 0.0875rem;
	color: color('oxeye');
	display: block;
	width: 100%;
	padding: 1rem 1.25rem;
	background-color: transparent;
	border: .125rem solid color('ice');
	padding-right: 3.25rem;
	cursor: pointer;
}

.#{$CNAME}--select-button {
	position: absolute;
	top: 0;
	right: 0;
	width: 3.25rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}

.#{$CNAME}--select-arrow polygon {
	fill: transparent;
	stroke: color('ice');
	stroke-linejoin: round;
	stroke-width: .125rem;
}

.#{$CNAME}--select-wrapper:hover .#{$CNAME}--select-arrow polygon {
	fill: color('ice');
	stroke: color('ice');
}

// Panels

.#{$CNAME}--panels {}

.#{$CNAME}--panel {
	transition: opacity base-style-value('transition-duration-medium') ease;
}
