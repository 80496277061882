@import '../../css/vars';

$CNAME: 'c-page-career-thank-you';


//---------------------------------------------------------------
// - Thank You Message
//---------------------------------------------------------------
.#{$CNAME}{
	#thank-you-msg{
	  .c-block-rebar--outer-wrapper{
	    padding-bottom:15px;
	    padding-top:0;
	  }

	  .p-full {
	    padding-top: 0;
	    margin-top: -2px;
	  }

	  .module--text{
	    .pd-full{
	      padding-top:5px;
	    }

	    h3{
	      color:color('oxeye');
	      font-size:32px;
	      line-height:(42px/32px);
	      text-transform:initial;
	      letter-spacing:normal;
	      @include font-style('muli-600-n');
	    }

	    p{
	      font-size:19px;
	      line-height: 1.68;
	    }
	  }
	}
}


//---------------------------------------------------------------
// - Button
//---------------------------------------------------------------
.#{$CNAME}{
	#back-to-home{
	  padding-top: 6px;

	  .c-block-rebar--outer-wrapper{
	    padding:0;
	  }

	  .c-block-button{

	  	.c-base-primary-button--label{
	      color: color('ice');
	      @include font-style('muli-700-n');
	      font-size:12px;
	      letter-spacing: 0.19px;
	      text-transform:uppercase;
	      border:none;
	      line-height: 1.67;
	      display: inline-block;
	      vertical-align: middle;
	    }

	    .c-block-button--button-holder{
	      text-align:left;
	    }

	    .c-base-primary-button--wave{
	      display:none !important;
	    }

	    .c-base-primary-button--element{
	      border:none;
	      position:relative;
	      padding:2px 42px 7px;
	      text-align: left;
	      min-width: 40px;
	      transition:none;
	      padding-right:0;

	      &:before{
	        content:"";
	       	background-image: url(/assets/ui/icons/back-home.svg);
	        width: 32px;
	        height: 24px;
	        background-size: 32px 24px;
	        display: inline-block;
	        background-repeat: no-repeat;
	        background-position: center;
	        position: absolute;
	        left: 0;
	        top: 0;
	        transition:background 250ms ease 250ms;
	      }

	      &:hover,
	      &:focus{
	        opacity: 1;

	        &:before{
	        	background-image: url(/assets/ui/icons/back-white.svg);
	        }

	        .c-base-primary-button--label{
	        	color:color('oxeye');
	        }
	      }
	    }
	  }
	}
}
