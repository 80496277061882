@import '../../css/vars';

$CNAME: 'c-base-background-image';

.#{$CNAME} {}

.#{$CNAME}.m-full {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	// These could be set higher up. Assuming they will be if you're shoving this inside something that supported background images already
	background-size: inherit;
	background-position: inherit;
	background-repeat: inherit;
}
