@import '../../css/vars';

$CNAME: 'c-block-back-to-overview-button';

.#{$CNAME} {
	display: inline-flex;
	align-items: flex-end;
	padding: .75rem 0;
	@include font-style('muli-700-n');
}

.#{$CNAME}--text {
	margin-left: .75rem;
	font-size: .75rem;
	line-height: 1.25rem;
	text-transform: uppercase;
	letter-spacing: .0125rem;
	color: color('ice');
	transition: color base-style-value('transition-duration-fast') ease;
}

.#{$CNAME}:hover .#{$CNAME}--text {
	color: color('oxeye');
}

.#{$CNAME} svg {
	width: 2rem;
}

.#{$CNAME} svg path {
	fill: color('ice');
	transition: fill base-style-value('transition-duration-fast') ease;
}

.#{$CNAME}:hover svg path {
	fill: color('oxeye');
}
