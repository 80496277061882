@import '../../css/vars';

$CNAME: 'c-block-page-controls';

.#{$CNAME} {
	margin-top: 3rem;
	display: flex;
	justify-content: center;
}

.#{$CNAME} > * + * {
	margin-left: 1.5rem;
}
