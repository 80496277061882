@import '../../css/vars';

$CNAME: 'c-card-conservation-entry';

.#{$CNAME} {
	display: block;
	width: 100%;
	position: relative;
}

.#{$CNAME}--card-holder {
	position: relative;
	display: inline-block;
	width: 100%;
}

.#{$CNAME}--image {
	position: relative;
	display: block;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 23.25rem;
	background-color: color('ice');
}

.#{$CNAME}--description {
	display: flex;
	width: 100%;
	background-color: transparent;
	transition: background-color 250ms ease;
}

.#{$CNAME} a:hover .#{$CNAME}--description {
	background-color: color('ice');
}

// H-type exception
.#{$CNAME} .#{$CNAME}--description h3 {
	transition: color 250ms ease;
}

.#{$CNAME}:hover .#{$CNAME}--description h3 {
	color: color('lapis');
}


.#{$CNAME}--icon {
	width: 2.5rem;
	padding: 1.25rem 0rem .75rem .75rem;
	flex-shrink: 0;
}

.#{$CNAME}--description.min-20rem .#{$CNAME}--icon {
	width: 4rem;
	padding: .75rem .25rem .75rem .75rem;
}

.#{$CNAME} .#{$CNAME}--icon .c-base-svg > * {
	fill: color('oxeye') !important;
	transition: fill 250ms ease;
}

.#{$CNAME}:hover .#{$CNAME}--icon .c-base-svg > * {
	fill: color('lapis') !important;
}


.#{$CNAME}--title {
	padding: 1.5rem 0;
	word-wrap: break-word;
	overflow-wrap: break-word;
	-webkit-hyphens: auto;
	hyphens: auto;
}

// Exception for hover states within bios
.#{$CNAME} .c-base-background-image {
	opacity: 1;
	transition: opacity 250ms ease;
}

.#{$CNAME}:hover .c-base-background-image {
	opacity: .4;
}
