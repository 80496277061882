@import '../../css/vars';
$CNAME: 'c-page-learn-more';

@mixin fields {

  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  input[type="file"],
  textarea,
  select,
  .hs-input {
    @content;
  }
}

.learn-more-form .hs-form,
.learn-more-form .wheel-form-wrap {

  @include placeholder {
    @include font-style('muli-700-n');
    color: color('oxeye');
    opacity: 1;
    text-transform: uppercase;
  }

  @include fields {
    text-transform: uppercase;

    @media (max-width:320px){
      padding: 6px 15px;
      font-size: 10px;
    }
  }

  .form-field-wrap{
    &.recaptcha{
      position: absolute;
      bottom: -18px;
      right: 240px;
      width: 100%;
      padding: 0 21px 30px;


      @media (max-width:991px){
        bottom: -30px;
      }

      @media (max-width:880px){
        bottom: 0;
        right: 0;
        position: relative;
        padding: 9px 12px 9px;

        .g-recaptcha > div {
          margin-left: 0;
        }
      }

      @media (max-width:767px){
        position: static;
        padding-top: 0;
      }
    }
  }


  .form-actions {
    width: 100%;

    .btn-wheel-form-submit{
      min-width: 220px;
      width: auto;
      
      @media (max-width: 767px) {
        min-width: 110px;
      }
    }

    .c-block-back-to-overview-button{
      float: left;
    }
  }

  .form-errors{
    position: absolute;
    bottom: -60px;
    right: 98px;
    width: auto;

    @media (max-width:991px){
      right: 111px;
    }

    @media (max-width: 767px) {
      position: relative;
      right: 0;
      left: 0;
      padding: 17px 16px 0!important;
      margin-top: 0;
      bottom: auto;
      top: 0;
    }
  }
}