@import '../../css/vars';

$CNAME: 'c-page-homepage';

.#{$CNAME} {
	position: relative;
}

.#{$CNAME}--content {
	position: relative;
	padding-bottom: 12rem;
	overflow: hidden;

	@media(max-width:767px){
		padding-top:15rem;
	}
}

@media (min-width: 48em) {
	.#{$CNAME}--content {
		padding-bottom: 0;
	}
}

// Add a gradient to the top so that particles fade out.
.#{$CNAME}--content::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 24rem;
	background: linear-gradient(0deg, rgba(color('ocean-1'), 0), rgba(color('ocean-1'), 1));
}

.#{$CNAME}--particles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	@media(max-width:1024px){
		top:100px;
	}
}

.#{$CNAME}--container {
	position: relative;
}

.#{$CNAME}--spacer {
	position: relative;
}

.#{$CNAME}--spacer.m-xs { height: 6.25vh }
.#{$CNAME}--spacer.m-sm { height: 12.5vh }
.#{$CNAME}--spacer.m-md { height: 18.75vh }
.#{$CNAME}--spacer.m-lg { height: 25vh }
.#{$CNAME}--spacer.m-xl { height: 31.25vh }
.#{$CNAME}--spacer.m-2xl { height: 37.5vh }
.#{$CNAME}--spacer.m-3xl { height: 43.75vh }
.#{$CNAME}--spacer.m-4xl { height: 50vh }

@media (min-width: 48em) {
	.#{$CNAME}--spacer.m-xs { height: 9.375vh }
	.#{$CNAME}--spacer.m-sm { height: 18.75vh }
	.#{$CNAME}--spacer.m-md { height: 28.125vh }
	.#{$CNAME}--spacer.m-lg { height: 37.5vh }
	.#{$CNAME}--spacer.m-xl { height: 46.875vh }
	.#{$CNAME}--spacer.m-2xl { height: 56.25vh }
	.#{$CNAME}--spacer.m-3xl { height: 65.625vh }
	.#{$CNAME}--spacer.m-4xl { height: 75vh }
}

@media (min-width: 80em) {
	.#{$CNAME}--spacer.m-xs { height: 12.5vh }
	.#{$CNAME}--spacer.m-sm { height: 25vh }
	.#{$CNAME}--spacer.m-md { height: 37.5vh }
	.#{$CNAME}--spacer.m-lg { height: 50vh }
	.#{$CNAME}--spacer.m-xl { height: 62.5vh }
	.#{$CNAME}--spacer.m-2xl { height: 75vh }
	.#{$CNAME}--spacer.m-3xl { height: 87.5vh }
	.#{$CNAME}--spacer.m-4xl { height: 100vh }
}


//-----------------------------------------------------
// - animated keywords
//-----------------------------------------------------


//animation text
.section-animate-text{
	h2{
		color:#fff;
		font-size: 1.75rem;
    	line-height: 2.4rem;
    	padding-bottom:10px;

    	@media (max-width:320px){
    		font-size: 24px;
    	}
	}
}

.typed-keywords {
  display: inline-block;
  position: relative;
  -webkit-transition: width 0.6s cubic-bezier(0.8, 0, 0.2, 1);
  transition: width 0.6s cubic-bezier(0.8, 0, 0.2, 1);
  -webkit-transform-style: flat;
  transform-style: flat;
  -webkit-perspective: 600px;
  perspective: 600px
}

.typed-keywords .keyword {
  	display: inline-block;
  	white-space: nowrap;
  	position: absolute;
  	left: 0;
  	opacity: 0;
    letter-spacing:0.34px;
}

.typed-keywords .keyword:first-child {
  position: relative;
  top: auto;
  left: auto;
  opacity: 1
}

.section-animate-text .typed-keywords .keyword {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border: none;
  letter-spacing: 0;
  visibility:hidden;
  margin:0;
  line-height: normal;
}

.section-animate-text .typed-keywords .keyword.text-slide-up {
  -webkit-animation: textSlideUp 0.6s both alternate;
  animation: textSlideUp 0.6s both alternate
}

.section-animate-text .typed-keywords .keyword.active {
  position: relative;
  display: inline-block;
  -webkit-animation: textSlideIn .7s both alternate;
  animation: textSlideIn .7s both alternate;
  opacity:1;
  visibility:visible;
}

span.text-animate {
	display: inline-block;
	padding: 0;
	position: relative;
	margin-top: 0;

	@media (max-width:767px){
		display:block;
		padding: 0;
	}
}

span.text-before {
    padding-right: 7px;
}

@keyframes textSlideUp {
  0% {
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg);
    opacity: 1
  }
  60%{
    transform: translate3d(0, 0, -20%) rotate3d(0, 0, 0, 0deg);
    opacity: 1
  }
  100% {
    transform: translate3d(0, -50%, 0) rotate3d(1, 0, 0, 35deg);
    opacity: 0
  }
}

@keyframes textSlideIn {
  0% {
    transform:translateY(100%);
  }
  60%{
  	transform:translateY(-20%);
  }
  100% {
    transform:translateY(0);
  }
}

.homepage-rotating-sections{

	.section-animate-text{
		margin:0 auto;
		
		h2{
			line-height: normal;
		}
	}

	.#{$CNAME}--text-content-container{
		.c-base-text{
			margin:0 auto;

			p{
				
			}

			h3{
				padding-bottom: 13px;
			}
		}
	}

	.c-base-primary-button{
		text-align:right;
		width:100%;

		@media( max-width:767px ){
			text-align:center;
		}

    	.c-base-primary-button--element{
    		margin-top: 60px;
    	}
	}

	.c-page-homepage--spacer{
		&:last-child{
			display:none;
		}
	}
}

@media (max-width:980px){
	.homepage-rotating-section .image-column{
		order:1;
	}

	.homepage-rotating-section .content-column{
		order:2;
	}
}

//-----------------------------------------------------
// - Technology
//-----------------------------------------------------
@media (max-width:767px){
	.homepage-rotating-section-technology{
		.section-animate-text{
			max-width: 300px;
	   		margin-left: 0;
		}

		span.text-animate{
			display:inline-block;
		}
	}
}

//-----------------------------------------------------
// - Social Media Icons
//-----------------------------------------------------
.c-block-ocean--icons{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;

    .c-base-social-icon{
		margin:0 8px;
		z-index:999999999999999;
		transition:none;
	}
}



