@import '../../css/vars';

$CNAME: 'c-base-video';

.#{$CNAME}--wrapper {
	padding-top: 56.25%;
	position: relative;
}

.#{$CNAME}--wrapper video {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

iframe.#{$CNAME}--embed,
.#{$CNAME}--embed iframe {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.#{$CNAME}--cover {
	background-size: cover;
	background-position: center;
}
