@import '../../css/vars';

$CNAME: 'c-base-social-icon';

.#{$CNAME} {
	position: relative;
	display: block;
	padding: .5625rem;
	transition: background-color base-style-value('transition-duration-extra-fast') ease;
}

.#{$CNAME}:hover {
	background-color: color('ice');
}

.#{$CNAME}--svg {
	width: 1.125rem;
	height: 1.125rem;
}

.#{$CNAME}--path {
	fill: color('oxeye');
	transition: fill base-style-value('transition-duration-extra-fast') ease;
}

.#{$CNAME}:hover .#{$CNAME}--path {
	fill: color('lapis');
}
