// Applies styles that reset general browser behavior to a comfortable working state.

html {
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
	font-size: 100%;
	line-height: 1;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
	border: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-style: inherit;
	text-decoration: none;
	vertical-align: baseline;
	background: transparent;
}

body {
	line-height: 0;
	font-size: 0;
}

img {
	max-width: 100%;
	height: auto;
	border-style: none;
	vertical-align: middle;
}

picture,
.picture {
	display: block;
}

picture img,
.picture img {
	display: block;
	width: 100%;
}

a {
	color: currentColor;
}

ol,
ul {
	list-style: none;
}

[type='button'],
// Not checkbox
[type='color'],
[type='date'],
[type='datetime-local'],
[type='email'],
[type='file'],
[type='month'],
[type='number'],
[type='password'],
// Not radio
[type='range'],
[type='reset'],
[type='search'],
[type='submit'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
select,
textarea,
button {
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}

select::-ms-expand {
	display: none;
}

::placeholder {
	color: currentColor;
	opacity: .5;
}

table,
tr,
th,
td {
	border-collapse: collapse;
	border-spacing: 0;
	text-align: left;
}
