@import '../../css/vars';

$CNAME: 'c-block-button';

.#{$CNAME}--button-holder {
	text-align: center; // This is to force center the button within the block
}

.#{$CNAME}--button-holder > * {

}
