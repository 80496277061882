// Entry point for site CSS, includes all other styles.

@import 'vars';
@import 'layers';
@import 'font_faces';
@import 'reset';
@import 'grid';
@import 'vars_h-type';

// Components
@import '../components/BaseAnimatedBorder/BaseAnimatedBorder';
@import '../components/BaseBackgroundImage/BaseBackgroundImage';
@import '../components/BaseButtonHamburger/BaseButtonHamburger';
@import '../components/BaseButtonPrimary/BaseButtonPrimary';
@import '../components/BaseImage/BaseImage';
@import '../components/BaseLayoutStandard/BaseLayoutStandard';
@import '../components/BaseLayoutStandardSignup/BaseLayoutStandardSignup';
@import '../components/BaseLayoutJobApplicationForm/BaseLayoutJobApplicationForm';
@import '../components/BaseSocialIcon/BaseSocialIcon';
@import '../components/BaseSVG/BaseSVG';
@import '../components/BaseTabs/BaseTabs';
@import '../components/BaseText/BaseText';
@import '../components/BaseVideo/BaseVideo';
@import '../components/BlockBackToOverviewButton/BlockBackToOverviewButton';
@import '../components/BlockBios/BlockBios';
@import '../components/BlockButton/BlockButton';
@import '../components/BlockDividerHorizontal/BlockDividerHorizontal';
@import '../components/BlockFooterCompact/BlockFooterCompact';
@import '../components/BlockHeader/BlockHeader';
@import '../components/BlockHoveringObjects/BlockHoveringObjects';
@import '../components/BlockImage/BlockImage';
@import '../components/BlockImageAndText/BlockImageAndText';
@import '../components/BlockImageAndTextRows/BlockImageAndTextRows';
@import '../components/BlockImageAndTextTabs/BlockImageAndTextTabs';
@import '../components/BlockImagesAndTextGrid/BlockImagesAndTextGrid';
@import '../components/BlockOcean/BlockOcean';
@import '../components/BlockPageControls/BlockPageControls';
@import '../components/BlockParticles/BlockParticles';
@import '../components/BlockTestimonialsCarousel/BlockTestimonialsCarousel';
@import '../components/BlockRebar/BlockRebar';
@import '../components/BlockSocialMediaIcons/BlockSocialMediaIcons';
@import '../components/BlockTextCentered/BlockTextCentered';
@import '../components/BlockVideo/BlockVideo';
@import '../components/CardCareerInsightsEntry/CardCareerInsightsEntry';
@import '../components/CardConservationEntry/CardConservationEntry';
@import '../components/CardMediaEntry/CardMediaEntry';
@import '../components/PageAboutUs/PageAboutUs';
@import '../components/PageBio/PageBio';
@import '../components/PageCareerInsights/PageCareerInsights';
@import '../components/PageCareerInsightsEntry/PageCareerInsightsEntry';
@import '../components/PageCareers/PageCareers';
@import '../components/PageCareerEntry/PageCareerEntry';
@import '../components/PageConservation/PageConservation';
@import '../components/PageCareerJobForm/PageCareerJobForm';
@import '../components/PageCareerThankYou/PageCareerThankYou';
@import '../components/PageConservationEntry/PageConservationEntry';
@import '../components/PageHomepage/PageHomepage';
@import '../components/PageMedia/PageMedia';
@import '../components/PageMediaEntry/PageMediaEntry';
@import '../components/PagePage/PagePage';
@import '../components/PageLearnMore/PageLearnMore';
@import '../components/PageLearnMoreThankYou/PageLearnMoreThankYou';
// AUTO-INJECT-HERE - Don't edit or touch this comment unless you know what you're doing.

/* Body
----------------------------- */

html {
	background: linear-gradient(180deg, color('ocean-1'), color('ocean-2'));
}

body {
	overflow-x: hidden;
	opacity: 1.0;
	transition: opacity 0.33s linear 0.25s;
	position: relative;
}

// Disable film grain due to performance issues.
// body::before {
// 	content: '';
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	background-image: url('/assets/ui/noise.gif');
// 	background-size: 10rem 10rem;
// 	background-repeat: repeat;
// 	pointer-events: none;
// 	opacity: .05;
// }

body.content-loading {
	visibility: hidden;
	opacity: 0.0;
}

main {
	width: 100%;
}

/* Site Container
----------------------------- */

.site--container,
.site--narrow-container {
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
}

// For use in the main column in a single column layout
@media (min-width: 32em) {.site--container {max-width: calc(100% - 3rem)}}
@media (min-width: 48em) {.site--container {max-width: calc(100% - 6rem)}}
@media (min-width: 64em) {.site--container {max-width: calc(100% - 9rem)}}
@media (min-width: 80em) {.site--container {max-width: calc(100% - 12rem)}}
@media (min-width: 92em) {.site--container {max-width: 80rem}}

// For use in the main column in a sidebar layout (where the sidebar is 20rem wide)
@media (min-width: 52em) {.site--narrow-container {max-width: calc(100% - 3rem)}}
@media (min-width: 68em) {.site--narrow-container {max-width: calc(100% - 6rem)}}
@media (min-width: 84em) {.site--narrow-container {max-width: calc(100% - 9rem)}}
@media (min-width: 100em) {.site--narrow-container {max-width: calc(100% - 12rem)}}
@media (min-width: 112em) {.site--narrow-container {max-width: 80rem}}

/* Padding
----------------------------- */

.p-full {padding: 1.5rem;}
.p-half {padding: 0.75rem}

.p-full-x {padding-left: 1.5rem; padding-right: 1.5rem}
.p-full-y {padding-top: 1.5rem; padding-bottom: 1.5rem}

.p-half-x {padding-left: 0.75rem; padding-right: 0.75rem}
.p-half-y {padding-top: 0.75rem; padding-bottom: 0.75rem}

.p-full-t {padding-top: 1.5rem}
.p-full-r {padding-right: 1.5rem}
.p-full-b {padding-bottom: 1.5rem}
.p-full-l {padding-left: 1.5rem}

.p-half-t {padding-top: 0.75rem}
.p-half-r {padding-right: 0.75rem}
.p-half-b {padding-bottom: 0.75rem}
.p-half-l {padding-left: 0.75rem}

/* Focus Style
----------------------------- */

/* Hide focus indicator if focused via mouse, but not via keyboard */
.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
}

/* Bubbles
----------------------------- */

.bubbles {
	position: absolute;
	display: none;
}

.bubbles.m-top-left     { top: 0; left: 0; }
.bubbles.m-top-right    { top: 0; right: 0; }
.bubbles.m-bottom-right { bottom: 0; right: 0; }
.bubbles.m-bottom-left  { bottom: 0; left: 0; }

.bubbles--bubble {
	position: absolute;
	width: 1.5rem;
	height: 1.5rem;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.bubbles--bubble-image {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('/assets/ui/bubble.png'); // CS Debug
	//background-color: rgba(255,0,0,.25);
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}

/* Animation
----------------------------- */

.animate {
	opacity: 0;
	transition: opacity 500ms ease 100ms;
}

.animate.visible {
	opacity: 1;
}

/* Anti-Accessibility
----------------------------- */

::-moz-focus-inner {
	border: 0;
}
