@import '../../css/vars';

$CNAME: 'c-page-careers';



.#{$CNAME}--entries{
	max-width: 1206px;
    margin: 0 auto;
}

.#{$CNAME}--career-insights-button {
	margin-top: 3rem;
	display: flex;
	justify-content: center;
}

.#{$CNAME}--career-insights-filters {
	margin: 4rem calc(-50vw + 50%);
	padding: 4rem calc(50vw - 50% + 1.5rem);
	background-color: color('obsidian');
}

.#{$CNAME}--career-insights-filters-controls {
	display: flex;
	flex-wrap: wrap;
	justify-content: stretch;
	margin: 0 -1.25rem;
}

.#{$CNAME}--career-insights-filters-controls > * {
	flex: 0 0 auto;
	margin: 1.25rem;
}

.#{$CNAME}--career-insights-filters select {
	flex: 1 0 12rem;
	display: inline-block;
	padding: 1.25rem;
	@include font-style('sans-700-n');
	background-color: transparent;
	border: .125rem solid color('ice');
	position: relative;
	cursor: pointer;
	overflow: hidden;
	font-size: .875rem;
	line-height: 1.25;
	text-transform: uppercase;
	letter-spacing: .0875rem;
	color: color('oxeye');
	background-image: url('/assets/ui/icons/select-arrow.svg');
	background-position: calc(100% - 2rem) 50%;
	background-repeat: no-repeat;
}

.#{$CNAME}--career-insights-filters select option {
	color: unset;
	background-color: unset;
}

.#{$CNAME}--reset-button {
	text-align: right;
	cursor: pointer;
}

.#{$CNAME}--reset-button span {
	@include font-style('muli-400-n');
	font-size: 0.75rem;
	line-height: 1rem;
	color: color('ice');
	text-decoration: underline;
}

.#{$CNAME}--entry {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1.25rem 1.5rem;
	padding:27px 27px;
	border: .125rem solid color('ice');
	@include font-style('muli-400-n');
	font-size: 1.1875rem;
	line-height: 2rem;
	color: color('oxeye');
	transition: all 250ms;
	min-height: 122px;
}

.#{$CNAME}--entry-title{
	@media (min-width:767px){
		max-width:343px;
		padding-right: 10px;
	}

	@media (max-width:991px){
		line-height: 1.6rem;
		padding-right: 10px;
	}
}

.#{$CNAME}--entry-apply {
	flex: 0 0 auto;
	width: 5.5rem;
	font-size: 0.75rem;
	font-weight: 900;
	letter-spacing: 0.025rem;
	text-transform: uppercase;
	color: color('ice');
	background-image: url('/assets/ui/icons/right-arrow.svg');
	background-position: 90% 50%;
	background-repeat: no-repeat;
	background-size: auto 1rem;
}

.#{$CNAME}--entry:hover {
	background-color: color('ice');
	color: color('blue-zodiac');
}

.#{$CNAME}--entry:hover .#{$CNAME}--entry-apply {
	color: color('blue-zodiac');
	background-image: url('/assets/ui/icons/right-arrow-hover.svg');
	background-position: 100% 50%;
}

.#{$CNAME}--more-button {
	display: flex;
	justify-content: space-around;
}

/*
* Careers introduction
*/

.#{$CNAME} #career-banner-img{
	.c-block-rebar--container{
		
	}

	.module--image{
		max-width:1158px;
    	margin: 0 auto;
	}
}

.#{$CNAME} #careers-introduction{
	.c-block-rebar--outer-wrapper{
		padding-top: 10px;

		.module--text{
			h1{
				padding-bottom: 7px;
			}

			p.large,
			h6{
				font-size:19px;
				line-height:(32px/19px);
				color:color('oxeye');
				@include font-style('muli-400-n');
				margin-top: 2rem;
			}
		}
	}
}

/*
* Careers List 
*/

.#{$CNAME}--entry-item {
    width: 50%;
    float: left;

    &:nth-child(2n+1){
    	clear:both;
    }
}

@media (max-width: 991px ){
    .#{$CNAME}--entry{
	    font-size: 16px;
	    padding: 1rem;
    }
}

@media (max-width: 767px) {
	.#{$CNAME}--entry-item {
		width:100%;
	}

}
