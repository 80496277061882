@import '../../css/vars';

$CNAME: 'c-block-rebar';

$sidebarLayout: 'c-base-layout-sidebar';

.#{$CNAME} {}

/* Meta
============================================================= */

.#{$CNAME}--meta {
	display: none;
	justify-content: space-between;
	background-color: #f1f5f8;
	border: .0625rem solid #e3e5e8;
	padding: 1.5rem;
}

.#{$CNAME}--meta[data-block-id="null"] {
	display: none !important;
}

body.show-module-meta .#{$CNAME}--meta {
	display: flex;
}

.#{$CNAME}--meta-title {
	font-family: system-ui, BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	color: #29323d;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 300;
	text-align: center;
}

.#{$CNAME}--meta-link {
	font-family: system-ui, BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	font-size: .875rem;
	line-height: 1.125rem;
	font-weight: 500;
	color: #ffffff;
	background-image: linear-gradient(#da5a47, #cc5643);
	height: 2rem;
	display: block;
	border-radius: .125rem;
	padding: .375rem .75rem;
	border: none;
	text-align: center;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	box-shadow:
		inset 0 0 0 .0625rem transparent,
		0 0 0 .0625rem rgba(0, 0, 0, .025),
		0 .0625rem .0625rem rgba(0, 0, 0, .1);
}

.#{$CNAME}--meta-link:hover {
	background-image: linear-gradient(#bf503f, #b34b3b);
}

.#{$CNAME}--meta-link:active {
	background-image: linear-gradient(#8c3b2e, #994032);
}

/* Outer Wrapper
============================================================= */

.#{$CNAME}--outer-wrapper[data-top="0"] {padding-top: 0}
.#{$CNAME}--outer-wrapper[data-top="1"] {padding-top: .75rem}
.#{$CNAME}--outer-wrapper[data-top="2"] {padding-top: 1.5rem}
.#{$CNAME}--outer-wrapper[data-top="3"] {padding-top: 2.25rem}
.#{$CNAME}--outer-wrapper[data-top="4"] {padding-top: 3rem}
.#{$CNAME}--outer-wrapper[data-top="5"] {padding-top: 3.75rem}
.#{$CNAME}--outer-wrapper[data-top="6"] {padding-top: 4.5rem}
.#{$CNAME}--outer-wrapper[data-top="7"] {padding-top: 5.25rem}
.#{$CNAME}--outer-wrapper[data-top="8"] {padding-top: 6rem}
.#{$CNAME}--outer-wrapper[data-top="9"] {padding-top: 6.75rem}

.#{$CNAME}--outer-wrapper[data-bottom="0"] {padding-bottom: 0}
.#{$CNAME}--outer-wrapper[data-bottom="1"] {padding-bottom: .75rem}
.#{$CNAME}--outer-wrapper[data-bottom="2"] {padding-bottom: 1.5rem}
.#{$CNAME}--outer-wrapper[data-bottom="3"] {padding-bottom: 2.25rem}
.#{$CNAME}--outer-wrapper[data-bottom="4"] {padding-bottom: 3rem}
.#{$CNAME}--outer-wrapper[data-bottom="5"] {padding-bottom: 3.75rem}
.#{$CNAME}--outer-wrapper[data-bottom="6"] {padding-bottom: 4.5rem}
.#{$CNAME}--outer-wrapper[data-bottom="7"] {padding-bottom: 5.25rem}
.#{$CNAME}--outer-wrapper[data-bottom="8"] {padding-bottom: 6rem}
.#{$CNAME}--outer-wrapper[data-bottom="9"] {padding-bottom: 6.75rem}

/* Inner Wrapper
============================================================= */

.#{$CNAME}--inner-wrapper[data-top="0"] {padding-top: 0}
.#{$CNAME}--inner-wrapper[data-top="1"] {padding-top: .75rem}
.#{$CNAME}--inner-wrapper[data-top="2"] {padding-top: 1.5rem}
.#{$CNAME}--inner-wrapper[data-top="3"] {padding-top: 2.25rem}
.#{$CNAME}--inner-wrapper[data-top="4"] {padding-top: 3rem}
.#{$CNAME}--inner-wrapper[data-top="5"] {padding-top: 3.75rem}
.#{$CNAME}--inner-wrapper[data-top="6"] {padding-top: 4.5rem}
.#{$CNAME}--inner-wrapper[data-top="7"] {padding-top: 5.25rem}
.#{$CNAME}--inner-wrapper[data-top="8"] {padding-top: 6rem}
.#{$CNAME}--inner-wrapper[data-top="9"] {padding-top: 6.75rem}

.#{$CNAME}--inner-wrapper[data-bottom="0"] {padding-bottom: 0}
.#{$CNAME}--inner-wrapper[data-bottom="1"] {padding-bottom: .75rem}
.#{$CNAME}--inner-wrapper[data-bottom="2"] {padding-bottom: 1.5rem}
.#{$CNAME}--inner-wrapper[data-bottom="3"] {padding-bottom: 2.25rem}
.#{$CNAME}--inner-wrapper[data-bottom="4"] {padding-bottom: 3rem}
.#{$CNAME}--inner-wrapper[data-bottom="5"] {padding-bottom: 3.75rem}
.#{$CNAME}--inner-wrapper[data-bottom="6"] {padding-bottom: 4.5rem}
.#{$CNAME}--inner-wrapper[data-bottom="7"] {padding-bottom: 5.25rem}
.#{$CNAME}--inner-wrapper[data-bottom="8"] {padding-bottom: 6rem}
.#{$CNAME}--inner-wrapper[data-bottom="9"] {padding-bottom: 6.75rem}

/* Container
============================================================= */

.#{$CNAME}--container {
	position: relative;
}

.#{$CNAME}--container[data-width="auto"] {
	margin: 0;
	padding: 0;
}

.#{$CNAME}--container[data-width="autoExtended"] {
	margin: 0 calc(-50vw + 50%);
	padding: 0 calc(50vw - 50%);
}

@media (min-width: 48em) {
	.#{$sidebarLayout} .#{$CNAME}--container[data-width="autoExtended"] {
		margin: 0 calc(-50vw + 50% + 10rem);
		padding: 0 calc(50vw - 50% - 10rem);
	}
}

.#{$CNAME}--container[data-width="wide"] {
	margin: 0;
}

@media (min-width: 32em) {.#{$CNAME}--container[data-width="wide"] {margin: 0 -1.5rem}}
@media (min-width: 48em) {.#{$CNAME}--container[data-width="wide"] {margin: 0 -3rem}}
@media (min-width: 64em) {.#{$CNAME}--container[data-width="wide"] {margin: 0 -4.5rem}}
@media (min-width: 80em) {.#{$CNAME}--container[data-width="wide"] {margin: 0 -6rem}}

@media (min-width: 32em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wide"] {margin: 0}}
@media (min-width: 48em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wide"] {margin: 0}}
@media (min-width: 52em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wide"] {margin: 0 -1.5rem}}
@media (min-width: 68em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wide"] {margin: 0 -3rem}}
@media (min-width: 84em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wide"] {margin: 0 -4.5rem}}
@media (min-width: 100em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wide"] {margin: 0 -6rem}}

.#{$CNAME}--container[data-width="wideExtended"] {
	margin: 0 calc(-50vw + 50%);
	padding: 0 calc(50vw - 50%);
}

@media (min-width: 48em) {
	.#{$sidebarLayout} .#{$CNAME}--container[data-width="wideExtended"] {
		margin: 0 calc(-50vw + 50% + 10rem);
		padding: 0 calc(50vw - 50% - 10rem);
	}
}

@media (min-width: 32em) {.#{$CNAME}--container[data-width="wideExtended"] {padding: 0 calc(50vw - 50% - 1.5rem)}}
@media (min-width: 48em) {.#{$CNAME}--container[data-width="wideExtended"] {padding: 0 calc(50vw - 50% - 3rem)}}
@media (min-width: 64em) {.#{$CNAME}--container[data-width="wideExtended"] {padding: 0 calc(50vw - 50% - 4.5rem)}}
@media (min-width: 80em) {.#{$CNAME}--container[data-width="wideExtended"] {padding: 0 calc(50vw - 50% - 6rem)}}

@media (min-width: 32em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wideExtended"] {padding: 0 calc(50vw - 50%)}}
@media (min-width: 48em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wideExtended"] {padding: 0 calc(50vw - 50% - 10rem)}}
@media (min-width: 52em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wideExtended"] {padding: 0 calc(50vw - 50% - 1.5rem - 10rem)}}
@media (min-width: 68em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wideExtended"] {padding: 0 calc(50vw - 50% - 3rem - 10rem)}}
@media (min-width: 84em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wideExtended"] {padding: 0 calc(50vw - 50% - 4.5rem - 10rem)}}
@media (min-width: 100em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="wideExtended"] {padding: 0 calc(50vw - 50% - 6rem - 10rem)}}

.#{$CNAME}--container[data-width="slim"] {
	margin: 0;
}

@media (min-width: 32em) {.#{$CNAME}--container[data-width="slim"] {margin: 0 1.5rem}}
@media (min-width: 48em) {.#{$CNAME}--container[data-width="slim"] {margin: 0 3rem}}
@media (min-width: 64em) {.#{$CNAME}--container[data-width="slim"] {margin: 0 4.5rem}}
@media (min-width: 80em) {.#{$CNAME}--container[data-width="slim"] {margin: 0 6rem}}

@media (min-width: 32em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slim"] {margin: 0}}
@media (min-width: 48em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slim"] {margin: 0}}
@media (min-width: 52em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slim"] {margin: 0 1.5rem}}
@media (min-width: 68em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slim"] {margin: 0 3rem}}
@media (min-width: 84em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slim"] {margin: 0 4.5rem}}
@media (min-width: 100em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slim"] {margin: 0 6rem}}

.#{$CNAME}--container[data-width="slimExtended"] {
	margin: 0 calc(-50vw + 50%);
	padding: 0 calc(50vw - 50%);
}

@media (min-width: 48em) {
	.#{$sidebarLayout} .#{$CNAME}--container[data-width="slimExtended"] {
		margin: 0 calc(-50vw + 50% + 10rem);
		padding: 0 calc(50vw - 50% - 10rem);
	}
}

@media (min-width: 32em) {.#{$CNAME}--container[data-width="slimExtended"] {padding: 0 calc(50vw - 50% + 1.5rem)}}
@media (min-width: 48em) {.#{$CNAME}--container[data-width="slimExtended"] {padding: 0 calc(50vw - 50% + 3rem)}}
@media (min-width: 64em) {.#{$CNAME}--container[data-width="slimExtended"] {padding: 0 calc(50vw - 50% + 4.5rem)}}
@media (min-width: 80em) {.#{$CNAME}--container[data-width="slimExtended"] {padding: 0 calc(50vw - 50% + 6rem)}}

@media (min-width: 32em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slimExtended"] {padding: 0 calc(50vw - 50%)}}
@media (min-width: 48em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slimExtended"] {padding: 0 calc(50vw - 50% - 10rem)}}
@media (min-width: 52em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slimExtended"] {padding: 0 calc(50vw - 50% + 1.5rem - 10rem)}}
@media (min-width: 68em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slimExtended"] {padding: 0 calc(50vw - 50% + 3rem - 10rem)}}
@media (min-width: 84em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slimExtended"] {padding: 0 calc(50vw - 50% + 4.5rem - 10rem)}}
@media (min-width: 100em) {.#{$sidebarLayout} .#{$CNAME}--container[data-width="slimExtended"] {padding: 0 calc(50vw - 50% + 6rem - 10rem)}}

.#{$CNAME}--container[data-width="full"] {
	margin: 0 calc(-50vw + 50%);
}

@media (min-width: 48em) {
	.#{$sidebarLayout} .#{$CNAME}--container[data-width="full"] {
		margin: 0 calc(-50vw + 50% + 10rem);
	}
}

/* Background
============================================================= */

.#{$CNAME}--background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
}

.#{$CNAME}--background[data-bg-color="none"] {background-color: transparent}

.#{$CNAME}--background[data-bg-color="prototype-light"] {
	background-color: color('light');
}

.#{$CNAME}--background[data-bg-color="prototype-light-tint"] {
	background-color: color('light-tint');
}

.#{$CNAME}--background[data-bg-color="prototype-light-pattern"]:not([style*="background-image"]) {
	background-color: color('light-tint');
	background-image: url('/assets/ui/prototype-light-pattern.png');
	background-repeat: repeat;
	background-size: 1rem;
	background-position: center;
	background-attachment: fixed;
}

.#{$CNAME}--background[data-bg-color="prototype-dark-tint"] {
	background-color: color('dark-tint');
}

.#{$CNAME}--background[data-bg-color="prototype-dark"] {
	background-color: color('dark');
}

.#{$CNAME}--background[data-bg-color="prototype-dark-pattern"]:not([style*="background-image"]) {
	background-color: color('dark-tint');
	background-image: url('/assets/ui/prototype-dark-pattern.png');
	background-repeat: repeat;
	background-size: 1rem;
	background-position: center;
	background-attachment: fixed;
}

/* Overlay
============================================================= */

.#{$CNAME}--overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Content
============================================================= */

.#{$CNAME}--content {
	position: relative;
}
