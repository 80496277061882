@import '../../css/vars';

$CNAME: 'c-base-layout-standard';

.#{$CNAME} {
	position: relative;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: 100% auto;
	padding: 12rem 0 6rem 0;
}

@media (min-width: 48em) {
	.#{$CNAME} {
		padding: 13.5rem 0 6rem 0;
	}
}

@media (min-width: 80em) {
	.#{$CNAME} {
		padding: 15rem 0 12rem 0;
	}
}

.#{$CNAME}--main {
	position: relative;
}
