@import '../../css/vars';

$CNAME: 'c-block-divider-horizontal';

.#{$CNAME} {}

.#{$CNAME}--line {
	width: 100%;
	height: 0.875rem;
	background-image: url(/assets/ui/wavy-line.png);
	background-size: auto 0.875rem;
	background-repeat: repeat-x;
	background-position: center center;
}
