@import '../../css/vars';

$CNAME: 'c-block-video';

.#{$CNAME} {
	position: relative;
}

.#{$CNAME}--button {
	width: 5rem;
	height: 5rem;
	border: 0.25rem solid #ffffff;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: transparent;
	cursor: pointer;
}

.#{$CNAME}--button.m-hidden {
	display: none;
}

.#{$CNAME}--button::before,
.#{$CNAME}--button::after {
	content: '';
	width: 1.75rem;
	height: 0.25rem;
	position: absolute;
	left: 50%;
	top: 50%;
	transform-origin: calc(100% - 0.125rem) 50%;
	transform: translate(-50%, -50%) rotate(45deg);
	background-color: #ffffff;
}

.#{$CNAME}--button::after {
	transform: translate(-50%, -50%) rotate(-45deg);
}
