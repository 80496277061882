@import '../../css/vars';

$CNAME: 'c-page-career-job-form';


.#{$CNAME} {
	top: -30px;
    position: relative;
}


.#{$CNAME}--job-content{
	padding: 0 8px !important;
}

.#{$CNAME}--title{
	padding:0 !important;

	@media ( max-width:320px ){
		h1{
			font-size: 20px;
		}
	}
}

.#{$CNAME}--reference-number{
	padding:0 !important;
	padding-top:15px !important;
	padding-bottom: 12px !important;
}


.#{$CNAME}{
	.c-block-rebar--outer-wrapper,
	.c-block-rebar--container,
	.note-container{
		margin: 0 auto !important;
		max-width: 1066px !important;
	}
}

.c-block-rebar--container.note-container{
	margin: 0 auto;
	max-width: 1066px;
}

.#{$CNAME}{
	#job-form-intro{

		.c-block-rebar--outer-wrapper{
			padding-top:0 !important;
			padding-bottom: 16px !important;
		}
		
		.c-base-text{
			max-width:951px;
		}

		.p-full{
			padding-left:8px !important;
			padding-right:8px !important;
			padding-top:0 !important;
			padding-bottom: 0 !important;

			p{
				font-size: 19px;
				line-height: 1.68;
				@include font-style('muli-400-n');
			}
		}
	}
}

@media ( max-width:767px ){
	.#{$CNAME}--job-content{
		padding: 0 1.5rem !important;
	}

	.#{$CNAME}{
		#job-form-intro{
			.p-full{
				padding-left:1.5rem !important;
				padding-right:1.5rem !important;
			}
		}
	}
}