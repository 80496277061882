@import '../../css/vars';

$CNAME: 'c-page-newsletter-signup';

//===============================================================
// SIGN UP PAGE
// - Mixins
// - Helpers
// - Variables
// - Form
// - Wheel Form
// - Form - hubspot
// - Form Hubspot - Column 2
// - Error
// - Success
//===============================================================
//---------------------------------------------------------------
// - Mixins
//---------------------------------------------------------------
@mixin border-radius($values...) {
  @each $val in $values {
    -webkit-border-radius: #{$val};
    -moz-border-radius: #{$val};
    border-radius: #{$val};
  }
}
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
  $list:();
  @each $prop in $properties {
    $str: #{$prop} #{$duration} #{$easing};
    $list: join($list, #{$str}, comma);
  }
  -moz-transition: $list;
  -webkit-transition: $list;
  transition: $list;
}
// usage: Add placeholder style for input fields
@mixin placeholder {
  .placeholder { @content; }
  :-moz-placeholder { @content; }
  ::-moz-placeholder { @content; }
  :-ms-input-placeholder { @content; }
  ::-webkit-input-placeholder { @content; }
}

@mixin fields {

  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  input[type="file"],
  textarea,
  select,
  .hs-input{
    @content;
  }
}

//---------------------------------------------------------------
// - Helpers
//---------------------------------------------------------------
%clearfix {
  &:before {
    content: '';
    display: table;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.clearfix{
  @extend %clearfix;
  clear:both;
}

//---------------------------------------------------------------
// - Form
//---------------------------------------------------------------
html{
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

.job-application-form{
  margin: 0;
  padding: 0;

  * + * {
    margin: 0;
  }

  .c-block-rebar--container{
    margin: 0 !important;
    overflow-x:hidden !important;
  }
}

.job-application-form .hs-form,
.job-application-form .wheel-form-wrap{
  max-width: 1066px;
  margin: 0 auto;

  @include placeholder{
    @include font-style('muli-700-n');
    color:color('oxeye');
    opacity: 1;
    text-transform: uppercase;
  }

  @include fields {
    @include box-shadow( none );
    @include border-radius(0);
    @include animate(border-color background, 0.15s, ease-in-out);
    border: 2px solid color('ice');
    color:color('oxeye');
    background-color: transparent;
    display: block;
    width: 100%;
    height: 60px;
    padding: 6px 27px;
    font-size: 14px;
    line-height: 1.42857143;
    letter-spacing: 1.4px;
    text-align: left;
   	@include font-style('muli-700-n');
    text-transform:uppercase;

    &:hover,
    &:focus{
      border: 2px solid color('ice') !important;
      background-color: color('ice');
      color:color('blue-zodiac');
    }

    &:focus,
    &:active{
      border: 2px solid color('ice') !important;
      background-color: color('ice');
      color:color('blue-zodiac');
    }

    &.error{
      border: solid 2px color('pinkish-orange');
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: all 5000s ease-in-out 0s;
      transition-property: background-color, color;
    }
  }

  input[type="number"]{
    -moz-appearance:textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }
  }


  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  select::-ms-expand {
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
  }

  .hs-button,
  .btn-wheel-form-submit{
    @include box-shadow( none );
    @include border-radius(0);
    @include animate(border-color background, 0.15s, ease-in-out);
    border: 2px solid color('ice');
    color: color('oxeye');
    cursor: pointer;
    background-color: transparent;
    display: inline-block;
    width: auto;
    height: auto;
    padding: 17px 27px 19px;
    font-size: 14px;
    line-height: 1.42857143;
    letter-spacing: 1.4px;
    text-align: center;
    min-width: 220px;
    text-transform: uppercase;
   	@include font-style('muli-600-n');

    .c-base-primary-button--label{
      @include font-style('muli-600-n');
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.42857143;
    }

    &:hover,
    &:focus{
      border: 2px solid color('ice') !important;
      color: color('lapis');
      background-color: transparent;
    }

    @media (max-width: 767px) {
      min-width: 110px;
    }
  } 
}

//---------------------------------------------------------------
// - Wheel Form
//---------------------------------------------------------------
.job-application-form .wheel-form-wrap{
  margin: 0 auto;
  padding: 0 8px;
  margin-bottom: 18px;

  @media (max-width:767px){
    padding: 0 1.5rem;
  }

  .form-field-wrapper{
    ul.errors{
      display:none;
    }
  }

  form{
    @extend %clearfix;
    margin: 0 -25px;

    @media (max-width: 1024px) {
      margin: 0 -15px;
    }

  }

  .wf-label{

  }

  .wf-select{
    position:relative;

    .select-opener{
      width:20px;
      height:20px;
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      right: 14px;
      top: 19px;
      background-image: url(/assets/ui/icons/select-arrow.svg);
      background-repeat:no-repeat;
      background-size:16px 12px;
      background-position:center;
    }
  }

  .wf-radio-group {
    float: left;
    width: 100%;
  }

  .wf-radio{
    font-size: 17px;
    line-height: 1.88;
    letter-spacing: normal;
    color: color('oxeye');
    @include font-style('muli-400-n');

    @media (max-width:1200px){
      font-size:15px;
    }

    .wf-label{
      padding-left:9px;
    }

    input[type=radio] {
      opacity: 0;
      z-index: 9999;
    }

    label{
      position: relative;
      padding-left: 10px;

      @media (max-width:1024px) and (min-width:991px){
        font-size:14px;
      }
    }

    .overlay {
      display: inline-block;
      position: absolute;
      height: 16px;
      width: 16px;
      background-color: transparent;
      border-radius: 50%;
      border:1px solid color('oxeye');
      left: 0;
      top: 3px;

      @media (min-width:991px) and (max-width:1200px){
        top: 2px;
      }

      @media (max-width:768px){ 
        top: 1px;
      }

      &:before{
        content:"";
        width: 10px;
        height: 10px;
        background-color: color('ice');
        position:absolute;
        display:block;
        left: 2px;
        top: 2px;
        opacity: 0;
        border-radius: 50%;
        opacity:0;
      }
    }

    /* changed style when checked */
    input[type=radio]:checked + .overlay:before {
      opacity:1;
    }
  }

  .form-field-wrap{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
  }

  .form-field-wrap,
  .form-actions,
  .form-errors{
    margin: 0;
    padding: 9px 25px 30px;
    display: block;
    width: 100%;

    @media (max-width: 1280px) {
      padding: 17px 15px;
    }

    @media (max-width: 1024px) {
      padding: 8px 15px 10px;
    }
  }

  .form-field-wrap{
    width: 50%;
    float: left;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  /* address fields */
  .street-add-field-wrap{
    width:40%;

    @media (max-width: 991px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .city-field-wrap{
    width:20%;

    @media (max-width: 991px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .state-field-wrap{
    width:20%;

    @media (max-width: 991px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .zip-field-wrap{
    width:20%;

    @media (max-width: 991px) {
      width: 50%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  /* end address fields */

  .how-did-you-hear-wrap,
  .type-of-work-field-wrap,
  .location-opt-field-wrap,
  .sponsorship-field-wrap{
    .wf-group-heading{
      padding-bottom: 12px;
      
      .wf-label{
        font-size: 14px;
        font-weight: 700;
        line-height: 2.14;
        letter-spacing: 1.4px;
        color: color('ice');
        @include font-style('muli-700-n');
        text-transform:uppercase;
      }
    }
  }

  .how-did-you-hear-wrap{
    width: 74.5%;
    padding-right: 0;

    @media (max-width:1024px){
      width: 78%;
    }

    @media( max-width: 991px ){
      width:100%;
      padding:17px 15px 0;
    }

    .wf-radio-group{
      .wf-radio{
        float:left;
        width: 11.45%;
        margin-right: 35px;

        @media (max-width: 1200px) {
          margin-right: 1% !important;
          width: 13.66% !important;
        }

        @media (max-width: 991px) {
          width: 100% !important;
          margin-right:0 !important;
        }

        &:nth-child(2){
          width: 10.5%;
          margin-right: 37px;
        }

        /* QA Sheet row 23, this was removed so we need to adjust the numbering
        &:nth-child(3){
          width: 10.5%;
          margin-right: 37px;
        }
        */


        &:nth-child(3){
          width: 11.5%;
          margin-right: 40px;
        }

        &:nth-child(4){
          width: 23%;
          margin-right: 38px;

          @media (max-width: 1200px) {
            width: 26% !important;
          }

          @media (max-width: 991px) {
            width: 100% !important;
          }
        }

        &:last-child{
          width: 8.66%;
          margin-right: 0 !important;

          @media (max-width: 1200px) {
            width: 9.66%;
            margin-right: 0 !important;
          }

          @media (max-width: 991px) {
            width: 100% !important;
          }
        }
      }
    }
  }

  .other-field-wrap{
    width: 23.25%;
    padding-left: 0;
    padding-right: 0;
    margin-left: -11%;

    @media (max-width:1200px){
      margin-left: -14%;
      width: 27.25%;
    }

    @media (max-width:1024px) and (min-width:991px){
      width: 24%;
      margin-left: -14.5%;
    }

    @media( max-width: 991px ){
      width:100%;
      padding: 0 15px 32px;
      margin-left: 0;
    }

    input[name="how_did_you_hear_other"]{
      border: none!important;
      border-bottom: 2px solid color('ice') !important;
      margin-top: 25px;
      background: transparent!important;
      height: 40px;
      padding: 6px 10px;

      &:hover,
      &:focus{
        color: color('oxeye');
        background-color: transparent;
      }

      @media( max-width: 991px ){
        margin-top:0;
        height: 48px;
      }
    }
  }

  .type-of-work-field-wrap{
    width:100%;
    clear:left;

    .wf-radio{
      float:left;
      width: 13%;
      margin-right: 33px;

      @media (max-width:1200px ){
        width: 14%;
      }

      @media (max-width: 991px) {
        width: 50%;
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      &:first-child{
        width: 20%;

        @media (max-width: 991px) {
          width: 50%;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }

  .location-opt-field-wrap{
    width:100%;
    clear:left;

    .wf-group-heading{
      padding-bottom: 0;
    }

    .wf-group{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: stretch;

      .wf-group-heading{
        width:51%;
        float:left;

        @media (max-width:1200px){
          width: 60%;
        }

        @media (max-width:1024px){
          width: 73%;
        }

        @media(max-width:767px){
          width:100%;
        }
      }

      .wf-radio-group{
        float:left;
        width: 15%;

        @media (max-width:1024px){
          width: 27%;
        }

        @media(max-width:767px){
          width:100%;
        }

        .wf-radio{
          float:left;
          width:50%;
        }
      }
    }
  }

  .sponsorship-field-wrap{
    width:100%;
    clear:left;

    .wf-group-heading{
      padding-bottom: 0;
    }

    .wf-group{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: stretch;

      @media(max-width:767px){
        display:block;
      }

      .wf-group-heading{
        width:46%;
        float:left;

        @media (max-width:1200px){
          width: 54%;
        }

        @media (max-width:1024px){
          width: 73%;
        }

        @media(max-width:767px){
          width:100%;
        }
      }

      .wf-radio-group{
        float:left;
        width: 15%;

        @media (max-width:1024px){
          width: 27%;
        }

        @media (max-width:767px){
          width:100%;
        }

        .wf-radio{
          float:left;
          width:50%;
        }
      }
    }
  }

  .resume-field-wrap,
  .cover-letter-field-wrap{
    width:50%;

    @media (max-width:991px){
      width:100% !important;
    }

    label{
      height: 60px;
      width:100%;
      display:block;
      border: solid 2px color('ice');
      font-size: 14px;
      line-height: 2.14;
      letter-spacing: 1.4px;
      @include font-style('muli-700-n');
      color:color('oxeye');
      text-transform:uppercase;
      text-align: left;
      padding: 13px;
      position: relative;
      padding: 14px 144.2px 13px 144px;

      &:hover{
        background-color:color('ice');
      }

      @media (max-width:1280px){
        padding:14px 110px;
        font-size: 13px;
      }

      @media (max-width:991px){
        padding: 14px 27px!important;
        font-size: 14px;
        line-height: 2;
      }

      @media (max-width:767px){
        padding:14px 68px;
        font-size: 11.25px;
      }

      @media (max-width:480px){
        font-size: 9.5px;
        line-height: 3;
      }

      @media (max-width:375px){
        font-size: 8.5px;
        line-height: 3;
      }

      @media (max-width:320px){
        padding:14px 35px;
        font-size: 7.5px;
        line-height: 1.4;
      }

      &:before,
      &:after{
        content:"";
        position:absolute;
        width:30px;
        height:33px;
        background-image: url(/assets/ui/Icon-PDF.svg);
        position:absolute;
        left:0;
        top: 12px;
        transform: scale(.85);
      }

      &:before{ 
        left: 59.5%;

        @media (max-width:991px){
          left: auto;
          right: 75px;
        }

        @media (max-width:767px){
          right: 60px;
        }
      }

      &:after{
        background-image: url(/assets/ui/Icon-Doc.svg);
        left: 67.75%;

        @media (max-width:991px){
          left: auto;
          right: 37px;
        }

        @media (max-width:767px){
          right: 25px;
        }
      }
    }

    .wf-field{
      opacity:0;
      position:absolute;
    }
  }

  .resume-field-wrap{
    label{
      @media(max-width:320px){
        line-height: 4;
      }
    }
  }

  .cover-letter-field-wrap{
    label{
      padding: 14px 65.2px 13px 66px;

      @media (max-width:1024px){
        padding: 14px 27px;
      }

      @media (max-width:767px){
        padding: 17px 17px;
      }

      @media (max-width:480px){
        padding: 17px 10px;
        text-align: left;
        padding-left: 30px !important;
      }

      @media (max-width:375px){
        padding: 17px 10px;
        padding-left: 30px !important;
      }

      @media (max-width:320px){
        padding-right: 96px!important;
        line-height: 2;
      }

      &:before{
        left: 75.5%;

        @media (max-width:991px){
          left: auto;
          right: 75px;
        }

        @media (max-width:767px){
          right: 60px;
        }
      }

      &:after{
        left: 83.5%;

        @media (max-width:991px){
          left: auto;
          right: 37px;
        }

        @media (max-width:767px){
          right: 25px;
        }
      }
    }
  }

  .g-recaptcha {
    position: absolute;
    bottom: 20px;
    right: 373px;

    div {
      height: 78px;
    }

    @media (max-width:1366px){
      right:320px;
    }

    @media (max-width:1280px){
      bottom: 10px;
    }

    @media (max-width:991px){
      right: auto;
      bottom: 150px !important;
      top: auto;
      padding: 0 15px;
    }

  }

  .form-actions{
    width: 100%;
    float: left;
    text-align: left;
    margin-top: 22px;

    @media (max-width:767px){

    }

    .c-block-back-to-overview-button{
      padding-top:0;

      @media (max-width:767px){
        padding-top: 1.5rem;
        width:111px;


        svg {
          position: relative;
          bottom: 8px;
        }
      }

      .c-block-back-to-overview-button--text{
        letter-spacing: 0.19px;
      }
    }

    .btn-wheel-form-submit{
      float:right;

      &.disabled{
        pointer-events:none;
        opacity:0.5;
      }

      @media (max-width:991px){
        margin-top: 12px;
      }
      @media (max-width:767px){
        min-width: 110px;
      }
      @media (max-width:320px){
        min-width: 110px;
        padding: 10px;
      }
    }

    @media (max-width: 991px) {
      width: 100%;
      margin-top: 100px !important;
      margin-bottom: 15px;
    }

    @media (max-width: 500px) {
      padding-right: 0;
    }
  }

  ul.errors{
    color: color('pinkish-orange');
    margin: 0;
    padding: 0;
    font-size: 17px;
    @include font-style('muli-400-n');
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    padding: 0 27px 15px;

    @media (max-width:767px){
      padding: 0 15px 15px;
    }

    li{
      margin: 0;
      padding: 0;
      &:before{
        display: none !important;
      }
    }
  }

  .form-errors{
    text-align: right;
    width: 100%;
    position: relative;
    display: block;
    padding: 0 25px 0;

    @media (max-width: 1280px){
      padding:0;
    }
  
    @media (max-width: 991px) {
      width: 100%;
      text-align: left;
      padding: 15px 0;
      margin-top: 20px;
      display: block;
      float: none;
    }

    ul{
      color: color('pinkish-orange');
      margin: 0;
      padding: 0;
      font-size: 17px;
      @include font-style('muli-400-n');
      line-height: 1.88;
      letter-spacing: normal;
      text-align: right;
      float: right;
      text-align: left;
      width: 100%;
      max-width: 220px;

      @media (max-width:991px){
        text-align: left;
        left: 0;
        width: 100%;
        display: block;
        float: none;
      }

      li{
        margin: 0;
        padding: 0;
        &:before{
          display: none !important;
        }
      }
    }
  }

  .form-field-recaptcha{
    .error-form-field-wrap{
      display:block !important;
      font-size: 15px;
      color: color('pinkish-orange');
      padding: 20px 0;
      @include font-style('muli-400-n');
    }
  }

  .error-form-field-wrap{
    .error-form-field-wrap{
      display:none !important ;
    }

    @include fields{
      border: solid 2px color('pinkish-orange') !important;
    }

    &.wf-input-field{
      border: solid 2px color('pinkish-orange') !important;
    }

    .wf-select .select-opener{
      background-image: url(/assets/ui/icons/select-arrow-error.svg);
    }

    .wf-group-heading .wf-label{
      color:color('pinkish-orange');
    }

    &.resume-field-wrap{
      .wf-group label{
        border-color:color('pinkish-orange');
      }
    }

    .error-message{
      margin:10px 0;
      padding:10px 0;
      font-size:12px;
      color:color('pinkish-orange');
      @include font-style('muli-400-n');
    }
  }

  .recaptcha-flag-wrap{
    display:none;
  }
}


//---------------------------------------------------------------
// - Form Note
//---------------------------------------------------------------
.c-base-layout-standard--form-note{
  padding-left: 8px !important;
  padding-right: 8px !important;

  @media (max-width:1024px) and (min-width:991px){
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  @media (max-width:767px){
    padding:0 1.5rem !important;
  }

  .h-type {
    max-width: none;
  }
  
}

//---------------------------------------------------------------
// - File Inputs
//---------------------------------------------------------------
.job-application-form {
  .wheel-form-wrap {

    .file-attached {

      &.resume-field-wrap,
      &.cover-letter-field-wrap {

        .wf-group{
          position:relative;
        }

        .file-remove {
          position: absolute;
          right: 12px;
          top: -9px;
          bottom: 0;
          margin: auto;
          font-size: 29px;
          height: 26px;
          line-height: 1.1;
          cursor:pointer;
        }

        label {
          padding:14px 0 14px;
          text-align: center;
          background: #96d5ea;
          color: #13284c;
          position: relative;
          display: -webkit-flex;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;

          &:after {
            display: none;
          }

          &:before {
            display: none;
          }

          @media (max-width:991px){
            padding:11px 65px !important;
            position: relative;
            text-align:left;
            padding-right: 20px !important;
            justify-content: flex-start;
          }

          .file-type{
            position: relative;
            white-space: nowrap;
            width: auto;
            text-overflow: ellipsis;
            display: inline-block;
            top:0;
            margin-right: 8px;
            font-size: 12px;

            @media (max-width:480px){
              letter-spacing: 0;
              font-size: 11px;
            }

            &:before{
              content: '';
              background-image: url(/assets/ui/icons/Icon-Attachment.svg);
              background-repeat: no-repeat;
              left: -39px;
              position: absolute;
              display: inline-block;
              width: 30px;
              height: 33px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }

          .file-name{
            font-size: 17px;
            font-weight: 400;
            line-height: 1.88;
            letter-spacing: normal;
            color: #13284c;
            text-transform: none;
            white-space: nowrap;
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            text-align: left;
            top: -2px;

            @media (max-width: 1280px) {
              width: 100px;
            }

            @media (max-width:991px){
              transform: scale(0.85);
              left: 0;
              position: relative;
              width: 460px;
            }

            @media (max-width: 800px){
              transform: scale(0.85);
              left: 0;
              position: relative;
              width: 260px;
            }

            @media (max-width: 767px){
              transform: scale(0.85);
              left:0;
              position: relative;
              width: 160px;
            }

            @media (max-width: 480px){
              transform: scale(0.85);
              left:0;
              position: relative;
              width: 85px;
            }
          }
        }
      }
    }
  }
}

/*
Define here the CSS styles applied only to Safari browsers
(any version and any device)
*/
@media not all and (min-resolution:.001dpcm){ 
  @supports (-webkit-appearance:none) {
  
  @media (min-width:1200px){
    .job-application-form .wheel-form-wrap .other-field-wrap {
      width: 25.5%;
      margin-left: -2.5%;
    }
  }

  .job-application-form .wheel-form-wrap .file-attached.resume-field-wrap label .file-type,
  .job-application-form .wheel-form-wrap .file-attached.cover-letter-field-wrap label .file-type {
    top: 0;
  }
  .job-application-form .wheel-form-wrap .how-did-you-hear-wrap .wf-radio-group .wf-radio{
    float: left;
    width: 12.45%;
    margin-right: 0;
  }
  .job-application-form .wheel-form-wrap .wf-radio label{
    padding-left: 0;
    font-size: 16px;


    @media (max-width:1280px){
      font-size: 14px;
    }
  }

  .job-application-form .wheel-form-wrap .wf-radio .overlay{
    @media (max-width:1280px){
      left: -3px;
      top: 1px;
    }
  }
  .job-application-form .wheel-form-wrap .wf-radio input[type=radio] {
    opacity: 0;
    z-index: 9999;
    margin-right: 7px;
  }
}}

.job-application-form .wheel-form-wrap .form-has-error+div .g-recaptcha {
  bottom: 54px;
}