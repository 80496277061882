@import '../../css/vars';

$CNAME: 'c-base-button-hamburger';

.#{$CNAME} {
	position: relative;
	width: 5rem;
	height: 4rem;
	cursor: pointer;
	transition: opacity base-style-value('transition-duration-extra-slow') ease;

	@media (min-width: 991px) {
		display: none;
	}
}

.m-hide-menu-button .#{$CNAME} {
	opacity: 0;
}

.#{$CNAME}--icon {
	position: absolute;
	top: .75rem;
	left: .75rem;
	width: 3.5rem;
	height: 2.5rem;
}

.#{$CNAME}--line {
	fill: none;
	stroke: color('oxeye');
	stroke-width: .125rem;
	transform-origin: center center;
	transition:
		transform base-style-value('transition-duration-fast') ease,
		stroke base-style-value('transition-duration-extra-fast') ease;
}

.#{$CNAME}:hover .#{$CNAME}--line {
	stroke: color('ice');
}

.#{$CNAME} .#{$CNAME}--line.m-1 {
	transform: none;
}

.#{$CNAME}[aria-expanded='true'] .#{$CNAME}--line.m-1 {
	transform: rotate(45deg) translate(0, .4375rem);
}

.#{$CNAME} .#{$CNAME}--line.m-2 {
	transform: translate(.25rem, 0) scaleX(.75);
}

.#{$CNAME}[aria-expanded='true'] .#{$CNAME}--line.m-2 {
	transform: translate(0, 0) scaleX(0);
}

.#{$CNAME} .#{$CNAME}--line.m-3 {
	transform: translate(.5rem, 0) scaleX(.5);
}

.#{$CNAME}[aria-expanded='true'] .#{$CNAME}--line.m-3 {
	transform: rotate(-45deg) translate(0, -.4375rem);
}
