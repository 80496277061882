@import '../../css/vars';

$CNAME: 'c-page-career-entry';

.#{$CNAME} {
	max-width: 1050px;
	margin: 0 auto;
	top: -30px;
    position: relative;
}

.#{$CNAME}--content {
	margin-top: 2rem;
}

@media (min-width: 48em) {
	.#{$CNAME}--content {
		margin-top: 4rem;
	}
}

.#{$CNAME}--cta {
    display: flex;
    justify-content: space-between;
    max-width: calc(64rem + 3rem);

    .c-block-back-to-overview-button{
    	margin-left: 1.5rem;

    	@media ( max-width: 767px ){
		    margin-right: 10px;
		    margin-left: 0;
		    width: 111px;

			svg {
				position: relative;
				bottom: 8px;
			}
		}
    }

	.c-base-primary-button--element{
		@media ( max-width: 767px ){
			min-width: 110px;
			padding: 1.25rem 10px;
		}
	}
}


.#{$CNAME}--job-content{
	padding-top:0 !important;

	@media ( max-width: 767px ){
		padding: 0 0 1.5rem!important;
	}
}

.#{$CNAME}--title{
	padding-top:0 !important;
	padding-bottom:0 !important;
}

.#{$CNAME}--reference-number{
	padding-top:15px !important;
	padding-bottom: 12px !important;
}

.#{$CNAME}--job-introduction p{
	font-size: 19px;
	line-height: 1.68;
}

.job-refNum p,
.job-refNum  .h-type{
	font-size: 16px;
  	line-height: 1.75;
  	letter-spacing: 1.04px;
  	@include font-style('muli-600-n');
  	color:color('oxeye');
  	text-transform:uppercase;
}
