@import '../../css/vars';

$CNAME: 'c-block-hovering-objects';

.#{$CNAME} {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.#{$CNAME}--object-offset {
	position: absolute;
	transform: rotate(0.01deg);
	transform-style: preserve-3d;
}

// Hide on mobile
.#{$CNAME} .#{$CNAME}--object-offset.m-hide-mobile { display: none; }
.#{$CNAME}.min-64rem .#{$CNAME}--object-offset.m-hide-mobile { display: block; }

// Hide on desktop
.#{$CNAME} .#{$CNAME}--object-offset.m-hide-desktop { display: block; }
.#{$CNAME}.min-64rem .#{$CNAME}--object-offset.m-hide-desktop { display: none; }

.#{$CNAME}--object-rotation {
	transform-origin: 0% 0%;
	transform-style: preserve-3d;
}

.#{$CNAME}--object-item {
	position: relative;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

// CS Debug Test
.#{$CNAME} .#{$CNAME}--object-item {
	//background-image: none !important;
	//background-color: rgba(0,255,0,.25);
}

/* Translucent Objects
----------------------------- */

.#{$CNAME}--object-scale.m-translucent.m-xs {
	transform: scale(0.33);
	opacity: 0.25;
	//filter: blur(18px);
}

.#{$CNAME}--object-scale.m-translucent.m-sm {
	transform: scale(0.5);
	opacity: 0.375;
	//filter: blur(12px);
}

.#{$CNAME}--object-scale.m-translucent.m-md {
	transform: scale(0.66);
	opacity: 0.5;
	//filter: blur(6px);
}

.#{$CNAME}--object-scale.m-translucent.m-lg {
	transform: scale(1.0);
	opacity: 0.75;
}

/* Opaque objects
----------------------------- */

.#{$CNAME}--object-scale.m-opaque.m-xs {
	transform: scale(0.33);
	opacity: 0.33;
	//filter: blur(18px);
}

.#{$CNAME}--object-scale.m-opaque.m-sm {
	transform: scale(0.5);
	opacity: 0.5;
	//filter: blur(12px);
}

.#{$CNAME}--object-scale.m-opaque.m-md {
	transform: scale(0.66);
	opacity: 0.66;
	//filter: blur(6px);
}

.#{$CNAME}--object-scale.m-opaque.m-lg {
	transform: scale(1.0);
	opacity: 1;
}
