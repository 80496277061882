@import '../../css/vars';

$CNAME: 'c-block-bios';

.#{$CNAME} {

}

.#{$CNAME} h3 > a {
	text-decoration: none; // Override for cases where we have an h3 > a in the bio grid
}

.#{$CNAME} h3 > a:hover {
	text-decoration: underline; // Override for cases where we have an h3 > a in the bio grid
}

// This exists so the hover can lighten to this color on hover
.#{$CNAME} .c-block-images-and-text-grid--image {
	background-color: color('ice');
}

// Exception for hover states within bios
.#{$CNAME} .c-block-images-and-text-grid--image .c-base-image picture {
	opacity: 1;
	transition: opacity 250ms ease;
}

.#{$CNAME} .c-block-images-and-text-grid--image:hover .c-base-image picture {
	opacity: .4;
}
