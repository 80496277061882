@import '../../css/vars';

$CNAME: 'c-block-particles';

.#{$CNAME} {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
}

.#{$CNAME}--particle-offset {
	position: absolute;
	top: 50%;
}

.#{$CNAME}--particle-animation {
	position: absolute;
	animation-name: particle-float;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	background-color: #ffffff;
	border-radius: 50%;
}

.#{$CNAME}--particle-animation.m-large {
	background-color: transparent;
	border-radius: 0;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}

.#{$CNAME}--particle-animation.m-large-1 {background-image: url('/assets/ui/speck-1.png');}
.#{$CNAME}--particle-animation.m-large-2 {background-image: url('/assets/ui/speck-2.png');}
.#{$CNAME}--particle-animation.m-large-3 {background-image: url('/assets/ui/speck-3.png');}
.#{$CNAME}--particle-animation.m-large-4 {background-image: url('/assets/ui/speck-4.png');}
.#{$CNAME}--particle-animation.m-large-5 {background-image: url('/assets/ui/speck-5.png');}