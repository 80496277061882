@import '../../css/vars';

$CNAME: 'c-base-image';

.#{$CNAME} {
	position: relative;
}

.#{$CNAME} img {
	max-width: 100%;
	transition: opacity base-style-value('transition-duration-slow') ease;
}

.#{$CNAME} img.m-shadow {
	@include base-style('image-shadow');
}
