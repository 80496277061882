@import '../../css/vars';

$CNAME: 'c-page-newsletter-signup';

//===============================================================
// SIGN UP PAGE
// - Mixins
// - Helpers
// - Variables
// - Form
// - Wheel Form
// - Form - hubspot
// - Form Hubspot - Column 2
// - Error
// - Success
//===============================================================
//---------------------------------------------------------------
// - Mixins
//---------------------------------------------------------------
@mixin border-radius($values...) {
  @each $val in $values {
    -webkit-border-radius: #{$val};
    -moz-border-radius: #{$val};
    border-radius: #{$val};
  }
}
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
  $list:();
  @each $prop in $properties {
    $str: #{$prop} #{$duration} #{$easing};
    $list: join($list, #{$str}, comma);
  }
  -moz-transition: $list;
  -webkit-transition: $list;
  transition: $list;
}
// usage: Add placeholder style for input fields
@mixin placeholder {
  .placeholder { @content; }
  :-moz-placeholder { @content; }
  ::-moz-placeholder { @content; }
  :-ms-input-placeholder { @content; }
  ::-webkit-input-placeholder { @content; }
}

@mixin fields {

  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  input[type="file"],
  textarea,
  select,
  .hs-input{
    @content;
  }
}

//---------------------------------------------------------------
// - Helpers
//---------------------------------------------------------------
%clearfix {
  &:before {
    content: '';
    display: table;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.clearfix{
  @extend %clearfix;
  clear:both;
}

//---------------------------------------------------------------
// - Form
//---------------------------------------------------------------
html{
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}
.signup-form{
  margin: 0;
  padding: 0 3px;

  * + * {
    margin: 0;
  }
}

.signup-form .hs-form,
.signup-form .wheel-form-wrap{

  @include placeholder{
    @include font-style('muli-700-n');
    color:color('oxeye');
    opacity: 1;
    text-transform: uppercase;
  }

  @include fields {
    @include box-shadow( none );
    @include border-radius(0);
    @include animate(border-color background, 0.15s, ease-in-out);
    border: 2px solid color('ice');
    color:color('oxeye');
    background-color: transparent;
    display: block;
    width: 100%;
    height: 60px;
    padding: 6px 27px;
    font-size: 14px;
    line-height: 1.42857143;
    letter-spacing: 1.4px;
    text-align: left;
   	@include font-style('muli-700-n');
    &:hover,
    &:focus{
      border: 2px solid color('lapis') !important;
      color: color('lapis');
      background-color: color('ice');
    }
    &.error{
      border: solid 2px color('pinkish-orange');
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: all 5000s ease-in-out 0s;
      transition-property: background-color, color;
    }
  }

  .hs-button,
  .btn-wheel-form-submit{
    @include box-shadow( none );
    @include border-radius(0);
    @include animate(border-color background, 0.15s, ease-in-out);
    border: 2px solid color('ice');
    color: color('oxeye');
    cursor: pointer;
    background-color: transparent;
    display: inline-block;
    width: 100%;
    height: auto;
    padding: 17px 27px 19px;
    font-size: 14px;
    line-height: 1.42857143;
    letter-spacing: 1.4px;
    text-align: center;
    min-width: auto;
    text-transform: uppercase;
   	@include font-style('muli-600-n');

    .c-base-primary-button--label{
      @include font-style('muli-600-n');
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.42857143;
    }

    &:hover,
    &:focus{
      border: 2px solid color('ice') !important;
      color: color('lapis');
      background-color: transparent;
    }
    @media (max-width: 767px) {
      min-width: 110px;
      width: auto;
    }
  }

  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  select::-ms-expand {
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
  }
}

//---------------------------------------------------------------
// - Wheel Form
//---------------------------------------------------------------
.signup-form .wheel-form-wrap{
  margin: 0;
  padding: 0 1.5rem 0;

  form{
    @extend %clearfix;
    margin: 0 -25px;
    @media (max-width: 991px) {
      margin: 0 -15px;
    }

  }

  .wf-select{
    position:relative;

    .select-opener{
      width:20px;
      height:20px;
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      right: 14px;
      top: 19px;
      background-image: url(/assets/ui/icons/select-arrow.svg);
      background-repeat:no-repeat;
      background-size:16px 12px;
      background-position:center;
    }
  }

  .form-field-wrap{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
  }

  .form-field-wrap,
  .form-actions,
  .form-errors{
    margin: 0;
    padding: 9px 25px 30px;
    display: block;
    width: 100%;

    @media (max-width: 991px) {
      padding: 17px 15px;
    }
  }

  .form-field-wrap{
    width: 50%;
    float: left;

    &.recaptcha {
      width: 75%;
      padding-right: 0;

      @media (max-width: 991px) {
        width: 65%;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding-right: 15px;
      }

      .g-recaptcha >div {
        margin-left: auto;
        @media (max-width: 768px) {
          margin-left: 0;
        }
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .form-actions{
    text-align: right;
    width: 25%;
    float: right;

    @media (max-width: 991px) {
      width: 35%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .form-errors{
    width: 50%;
    float: left;
    padding:0;

    @media (max-width: 767px) {
      width: 100%;
    }

    ul{
      color: color('pinkish-orange');
      margin: 0;
      padding: 0;
      font-size: 17px;
      @include font-style('muli-400-n');
      line-height: 1.88;
      letter-spacing: normal;
      text-align: left;
      li{
        margin: 0;
        padding: 0;
        &:before{
          display: none !important;
        }
      }
    }
  }

  .error-form-field-wrap{
    @include fields{
      border: solid 2px color('pinkish-orange') !important;
    }
  }

}
//---------------------------------------------------------------
// - Form - hubspot
//---------------------------------------------------------------

.signup-form .hs-form{
  @extend %clearfix;
  margin: 0 -25px;
  @media (max-width: 991px) {
    margin: 0 -15px;
  }
  .input,
  .hs-field-desc,
  fieldset,
  .hs_submit{
    margin: 0;
    padding: 0;
  }
  .hs-form-field,
  .hs_submit .actions,
  .hs_error_rollup{
    margin: 0;
    padding: 20px 25px 20px;
    width: 100%;
    display: block;
    @media (max-width: 991px) {
      padding: 17px 15px;
    }
  }
  .hubspot-link__container.sproket {
    display: none;
  }

  .hs-error-msg{
    display: none !important;
  }

  .hs-form-field ul.no-list.hs-error-msgs.inputs-list {
    display: none !important;
  }

  .hs_submit .actions{
    text-align: right;
    padding-top: 17px;
    width: 50%;
    float: right;
    @media (max-width: 767px) {
      width: 100%;
      text-align: left;
    }
  }
}
//---------------------------------------------------------------
// - Form Hubspot - Column 2
//---------------------------------------------------------------
.signup-form .hs-form{
  fieldset.form-columns-2 {
    @extend %clearfix;
    .hs-form-field {
      width: 50%;
      float: left;

      @media (max-width: 767px) {
      	width: 100%;
      }
    }
    &:nth-child(2n+1){
      clear: both;
    }
  }
}


//---------------------------------------------------------------
// - Error
//---------------------------------------------------------------
.signup-form .hs-form{
  .hs_error_rollup{
    padding-top: 12px;
    width: 50%;
    float: left;
    @media (max-width: 767px) {
      width: 100%;
    }
    ul{
      color: color('pinkish-orange');
      margin: 0;
      padding: 0;
      font-size: 17px;
      @include font-style('muli-400-n');
      line-height: 1.88;
      letter-spacing: normal;
      text-align: left;
      li{
        margin: 0;
        padding: 0;
        &:before{
          display: none !important;
        }
      }
    }
  }
}

//---------------------------------------------------------------
// - Thank You Message
//---------------------------------------------------------------
#thank-you-msg{
  .c-block-rebar--outer-wrapper{
    padding-bottom:15px;
    padding-top:0;
  }

  .p-full {
    padding-top: 0;
    margin-top: -2px;
  }

  .module--text{
    .pd-full{
      padding-top:5px;
    }

    h3{
      color:color('oxeye');
      font-size:32px;
      line-height:(42px/32px);
      text-transform:initial;
      @include font-style('muli-600-n');
    }

    p{
      font-size:19px;
      line-height: 1.68;
    }
  }

 
}


#back-to-home{
  padding-top: 6px;

  .c-block-rebar--outer-wrapper{
    padding:0;
  }

  .c-block-button{

    .c-base-primary-button--element{
      border:none;
      position:relative;
      padding: 7px 42px;
      text-align: left;
      min-width: 40px;

      &:before{
        content:"";
       	background-image: url(/assets/ui/icons/back-home.svg);
        width: 32px;
        height: 24px;
        background-size: 32px 24px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 0;
        top: 0;
      }

      &:hover,
      &:focus{
        opacity: 0.7;
      }
    }

    .c-base-primary-button--label{
      color: color('ice');
      @include font-style('muli-700-n');
      font-size:12px;
      letter-spacing: 0.19px;
      text-transform:uppercase;
      border:none;
      line-height: 1.67;
      display: inline-block;
      vertical-align: middle;
    }

    .c-block-button--button-holder{
      text-align:left;
    }

    .c-base-primary-button--wave{
      display:none !important;
    }
  }
}