@import '../../css/vars';

$CNAME: 'c-page-media-entry';

.#{$CNAME} {
}

.#{$CNAME}--title {
	margin-bottom: 2.5rem;
}

.#{$CNAME}--byline-bar {
	display: flex;
	align-items: center;
	margin-bottom: 2.5rem;
}

.#{$CNAME}--byline-bar .#{$CNAME}--publication {
	flex: 0 0 auto;
	width: 7.25rem;
	height: 5rem;
	margin-right: 2rem;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.#{$CNAME}--content {
	margin-top: 2rem;
}

@media (min-width: 48em) {
	.#{$CNAME}--content {
		margin-top: 4rem;
	}
}

.#{$CNAME}--cta {
	display: flex;
	justify-content: space-between;
	max-width: calc(64rem + 3rem); // 48rem is h-type max width, 3rem is p-full
}
